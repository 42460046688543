import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import FileCopyIcon from "@material-ui/icons/FileCopy";
import DeleteIcon from "@material-ui/icons/Delete";
import TableCell from "@material-ui/core/TableCell";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import EditIcon from "@material-ui/icons/Edit";
import GetAppIcon from "@material-ui/icons/GetApp";
import BlockIcon from "@material-ui/icons/Block";
import BrightnessHighIcon from "@material-ui/icons/BrightnessHigh";
import PublishIcon from "@material-ui/icons/CallMade";
import LinearProgress from '@material-ui/core/LinearProgress';

import IconButton from "../../components/material-ui/IconButton";

import {
  groupById, getRunConfigOption,
  CHK_FAIL, withTranslation, GlobalState,
}                                                               from "../../../core/utils";
import {
  getData, putData, postData, deleteData,
}                                                               from "../../../core/fetchService";
import { MESSAGE_STATUS, DIALOG_USER_STATE, BTN }               from "../../../core/constants";
import { fetchModelList, updateModel, fetchModelConfigList}     from "../../../features/settings";
import { setMessageState }                                      from "../../../features/messageInfo";

import EnhancedTable                                            from "../../components/projectTable";
import ConfirmDialog                                            from "../../components/confirmDialog";
import {
  viewStatus,
  linkStyle as trainStatusLinkStyle,
  Runnable,
  Runnables,
}                                                               from "../../components/taskStatus";

import Scrollable                                               from '../../components/helpers/scrollable';

import EditDialog                                               from "./editModelDialog";
import { ModelStatusDialog }                                    from "./modelStatusDialog";

function viewTrainStatus(row, cell) {
  const s_obj = row;
  const s_epochs = s_obj?.progress?._outer;
  const percent = s_epochs?.percent + s_obj?.progress?.percent / (s_epochs?.total || 1);
  return viewStatus(row, cell, {
    s_obj,
    percent,
    in_progress: {
      /* Note: statuses' names (below) must match to runnables' params */
      train: 'Train',
    }[s_obj?.execution?.task_name],
    replace: s => s == 'TrainError' ? 'Error' : s,
  });
}

class Train extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      rows: [],
      showModal: null,
      dialogTitle: null,
      modalContent: null,
      btnNameAgree: null,
      downloadModal: undefined,
      showModelStatusDialog: false,
      editDialogOpen: false,
      editClone: false,
      editModelId: null,
      nluConfigText: null,
    };

    this.eventSource = new EventSource(`/events`);
    this.scrollable = new Scrollable(this);
    this.globalState = new GlobalState(this.getModelList);
    const params = {
        getTaskName: m => m?.execution?.task_name,
        getStatus: m => m?.status,
        updateStatus: this.getModelList,
    };
    this.runnables = new Runnables({
      trainable: new Runnable({
        taskNames: ['train'],
        statusName: 'Train',
        ...params,
      }),
      exportable: new Runnable({
        taskNames: ['export'],
        statusName: 'Exporting',
        ...params,
      }),
      deployable: new Runnable({
        taskNames: ['deploy'],
        statusName: 'Deploying',
        ...params,
        addActiveStatuses: ['Exporting', 'Unloading'],
      }),
    });
    this.initMethodsForRunnables();
  }

  initMethodsForRunnables = () => {
    this.isActive = this.runnables.isObjActive;
    this.isRunning = this.runnables.isObjRunning;

    this.isStatusTraining =   this.runnables.trainable.isRunning;
    this.isStatusExporting =  this.runnables.exportable.isRunning;
    this.isStatusDeploying =  this.runnables.deployable.isRunning;

    this.isExportInDeploy = model =>
      this.runnables.deployable.isObjActive(model) && model.status == 'Exporting...';
  };

  getModelList = () => {
    this.props.dispatch(fetchModelList(this.props.projectId));
    this.props.dispatch(fetchModelConfigList(this.props.projectId));
  };

  componentDidMount() {
    this.eventSource.addEventListener("message", event => {
      this.getModelList();
    });

    // eventSource.onerror = function (e) {
    //  if (this.readyState == EventSource.CONNECTING) {
    //    console.log(`Переподключение (readyState=${this.readyState})...`);
    //  } else {
    //    console.log("Произошла ошибка.");
    //  }
    // };

    this.scrollable.componentDidMount();
    this.globalState.componentDidMount();
  }

  componentDidUpdate(prevProps) {
    this.runnables.componentDidUpdate(this.props.modelList);
    this.globalState.componentDidUpdate(prevProps, this.props);

    const { downloadModal, editModelId: id } = this.state;
    if (downloadModal && id ) {
      const m = this.props.modelList.find(m => m._id == id);
      if (m?.status == 'Ready')
        this.fetchDownloadStatus(id, m.name);
    }
  }

  componentWillUnmount() {
    this.runnables.componentWillUnmount();

    if (this.eventSource) {
      this.eventSource.close();
    }
    this.abortController.abort();
  }

  abortController = new window.AbortController();

  handleEditRow = id => () => {
    this.setState({ editDialogOpen: true, editModelId: id, editClone: false });
  };

  handleCloneRow = (id, name) => () => {
    this.setState({ editDialogOpen: true, editModelId: id, editClone: true });
  };

  fetchDownloadStatus = (id, name, action, silent) => {
    const { dispatch, t, modelList } = this.props;
    const close = () => this.setState({ downloadModal: null, editModelId: null });
    const show_link = url => {
      this.setState({ downloadModal: <a href={url} onClick={close}>{name}</a>, editModelId: null });
    };
    const show_popup = () => {
      this.setState({
        downloadModal: <div>{t('train.export_model_files', { name })}<br/><br/><LinearProgress/></div>,
        editModelId: id,
      });
    };
    if (this.isStatusExporting(modelList,id))
      return show_popup();
    getData(`/api/model/${id}/download/remote_status`, dispatch, data => {
      if (data.status == 'ready')
        return show_link(data.url);
      if (action)
        return action(show_popup);
      if (!silent)
        show_popup();
    });
  };

  handleDownloadModel = (id, name) => () => {
    this.fetchDownloadStatus(id, name, () => {
      getData(`/api/model/${id}/export`,
              this.props.dispatch,
              () => { this.fetchDownloadStatus(id, name); this.getModelList() });
    })
  };

  handlePublishModel = (id, name) => () => {
    this.requestTrainAction(id, 'deploy');
  };

  handleDeleteRow = (id, name) => () => {
    const { t } = this.props;
    this.setState({
      editModelId: id,
      showModal: 'delete',
      dialogTitle: t("train.delete"),
      modalContent: `${t("train.delete_trained_model")}: "${name}"?`,
      btnNameAgree: null,
    });
  };

  requestTrainAction = (id, action) => {
    const { dispatch, modelList } = this.props;
    const act_name = action == 'stop'
      ? (this.isStatusTraining(modelList, id)                           ? 'stop_train'
         : this.isStatusExporting(modelList, id)                        ? 'stop_export'
         : (this.isStatusDeploying(modelList, id)
            || this.isExportInDeploy(modelList.find(m => m._id == id))) ? 'stop_deploy'
         :                                                                'UNKNOWN')
      :                                                                   action;

    if (action == 'stop') // this is to disable STOP button in case a 'stop' request is delayed/hanged up
      dispatch(updateModel({ ...modelList.find(m => m._id == id), status: 'Waiting...'}));

    getData(`/api/model/${id}/${act_name}`, dispatch, data => {
      data.model && dispatch(updateModel(data.model));

      // make blue and add time 20 sec
      data.message && dispatch(setMessageState({
        snackBarMessages: data.message,
        snackBarVariant: MESSAGE_STATUS.INFO,
        snackBarState: true,
        snackBarDuration: 20000,
      }));
    });
  };

  handleTrainStopClick = (id, action) => {
    const { t, modelList } = this.props;
    const model = modelList.find(m => m._id == id);
    const { name, remote_name, status } = model || {};
    if (action == 'train' && remote_name && status != 'TrainError' )
      return this.setState({
        showModal: 'train',
        editModelId: id,
        dialogTitle: t('train.title', { name }),
        modalContent: t('train.start_question', { remote_name }),
      });
    if (action != 'stop')
      return this.requestTrainAction(id, action);
    const act_name = this.isStatusTraining(modelList, id)       ? 'stop_training'
      : this.isStatusExporting(modelList, id)                   ? 'stop_exporting'
      : this.isStatusDeploying(modelList, id)                   ? 'force_stop_deploying'
      : this.isExportInDeploy(modelList.find(m => m._id == id)) ? 'stop_deploying'
      : '';
    const warn_if_deploy = q => act_name != 'force_stop_deploying' ? q : (
      <div>
        <p style={{color:'red', fontWeight:'bold'}}>{t('train.warn_stop_deploying')}</p>
        <p>{q}</p>
      </div>
    );
    this.setState({
      showModal: 'stop',
      editModelId: id,
      dialogTitle: t('train.title', { name }),
      modalContent: warn_if_deploy(t('train.stop_question', { action: t('train.'+ act_name)})),
    });
  };

  handleCloseModal = (modalState) => {
    const { showModal: action, editModelId } = this.state;
    const { dispatch } = this.props;
    this.setState({ showModal: null });
    if (modalState != DIALOG_USER_STATE.AGREE)
      return;
    if (['train', 'stop'].includes(action)) {
      this.requestTrainAction(editModelId, action);
    } else if (action == 'delete') {
      deleteData(`/api/model/${editModelId}`, dispatch, this.getModelList);
    } else {
      CHK_FAIL(`unknown modal action: ${action}`);
    }
  };

  handleClickNewRow = () => {
    this.setState({ editModelId: null, editDialogOpen: true });
  };

  handleCloseEditDialog = (action) => {
    this.setState({ editDialogOpen: false });
    if (action === BTN.SAVE) {
      this.getModelList();
    }
  };

  render() {
    const {
      editModelId, downloadModal, editDialogOpen, editClone,
      showModal, dialogTitle, modalContent, btnNameAgree,
    } = this.state;
    const { modelList, t, modelConfigs, datasets } = this.props;
    const modelDict = groupById(modelList || []);

    const headCells = [
      { _id: "name",          width: "auto", label: t("train.trained_models"), textSearch: true },
      { _id: "type",          width: "5%",   label: t("train.nlu_type"),         filterOn: true },
      { _id: "trainSet",      width: "20%",  label: t("train.train_set"),        filterOn: true,   optional: true },
      { _id: "model_config",  width: "15%",  label: t("train.config"),           filterOn: true,   optional: true },
   // { _id: "nlu_train_url", width: "15%",  label: t("train.train_test_url"),   filterOn: true,   optional: false },
      { _id: "description",   width: "22%",  label: t("common.description"),   textSearch: true },
      { _id: "updatedAt",     width: "10%",  label: t("train.updatedAt"),        dateTime: true },
      { _id: "deployedAt",    width: "10%",  label: t("train.deployedAt"),       dateTime: true,   optional: false },
      {
        _id: "status",        width: "8%",  label: t("common.status"),
        link: row => () => {
          this.setState({ showModelStatusDialog: true, editModelId: row._id });
        },
        style: trainStatusLinkStyle(row => [row, this.isActive(row)], { External: 'green', TrainError: 'red', Error: 'magenta' }),
      },
    ];

    function addModelInfo(m) {
      const trainSet = m.trainSet.map(td_id => (datasets || []).find(d => d._id == td_id)?.name || '???').join(', ');
      const model_config = (modelConfigs || []).find(mc => mc._id == m.model_config)?.name || '???' 
      return {...m, trainSet, model_config };
    }

    const tableID = "trained";

    return (
      <Fragment>
        {showModal && <ConfirmDialog
          title={dialogTitle}
          content={modalContent}
          closeModal={this.handleCloseModal}
          btnNameAgree={btnNameAgree}
        />}
        {downloadModal && <ConfirmDialog
          title={t("train.download_model_files")}
          content={downloadModal}
          closeModal={() => this.setState({ downloadModal: undefined })}
          btnNameDisagree={t('common.close')}
          showBtnNameAgree={false}
        />}
        {editDialogOpen && <EditDialog
          _id={editModelId}
          editModel={modelList.find(model => model._id == editModelId)}
          isClone={editModelId && editClone}
          onClose={this.handleCloseEditDialog}
        />}
        <ModelStatusDialog
          isOpen={this.state.showModelStatusDialog}
          model={modelDict[editModelId]}
          onClose={() => { this.setState({ showModelStatusDialog: false }) }}
          trainable={this.runnables.trainable}
        />
        {modelList && <EnhancedTable
          id={tableID}
          useLocationSearch
          headCells={headCells}
          rows={modelList.map(addModelInfo)}
          viewCell={{ status: viewTrainStatus }}
          toolBarName={t("train.trained_models")}
          newRowTitle={t("train.new")}
          handleClickNewRow={() => this.handleClickNewRow()}
          handleClickUpdateRow={() => this.getModelList()}
          checkBoxTableCell={getRunConfigOption('noIDColumn')?.[tableID] ? undefined : (id, name, index) => (
            <TableCell padding="default">{index + 1}</TableCell>
          )}
          customBtns={(name, id) => {
            const model = modelDict[id];
            if (!model)
              return null;
            const isActive = this.isActive(model);
            const isRunning = this.isRunning(model);
            const isReady = model.status == 'Ready';
            const isError = model.status == 'Error';
            const isPublishError = isError && String(model.error).slice(0,4) == 'Deploy';
            const label = t(isActive ? "common.stop" : "common.train");
            const ICONS = [
              ['edit',      this.handleEditRow,       EditIcon],
              ['clone',     this.handleCloneRow,      FileCopyIcon],
              ['download',  this.handleDownloadModel, GetAppIcon],
              ['deploy',    this.handlePublishModel,  PublishIcon],
              ['delete',    this.handleDeleteRow,     DeleteIcon],
            ];
            const can_download = (...add_st) => model.remote_name
              && ['Ready','Error','Aborted',...add_st].includes(model.status);
            const disabled = {
              download: !can_download('Exporting...','Deploying...'),
              deploy:   !(['NLU_RASA','NLU_NB'].includes(model.type) && can_download()),
            };
            const more = i => disabled[i[0]] ? { disabled: true } : {};

            return <div style={{width: "25%", display: "flex"}}>
              {!getRunConfigOption('trainTestIcons') ? <>
                <span>
                  <Button style={{marginRight: 10}} variant="outlined" size="small"
                    onClick={() => this.handleTrainStopClick(id, isActive ? "stop" : "train")}
                    disabled={model.is_external || isActive && !isRunning && !this.isExportInDeploy(model)}
                  >
                    {label}
                  </Button>
                </span>
              </> : <>
                <IconButton
                  onClick={() => this.handleTrainStopClick(id, isActive ? "stop" : "train")}
                  disabled={model.is_external || isActive && !isRunning}
                  title={label}
                  Icon={isActive ? BlockIcon : BrightnessHighIcon}
                />
              </>}
              {ICONS.map(i => <IconButton
                key={i[0]}
                onClick={i[1](id, name)}
                title={t("common."+ i[0])}
                Icon={i[2]}
                {...more(i)}
              />)}
            </div>;
          }}
        />
        }
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  projectId: state.settings.projectId,
  projects: state.settings.projects,
  modelList: state.settings.modelList,
  modelConfigs: state.settings.modelConfigList,
  datasets: state.settings.projectDatasets,
});

export default withRouter(connect(mapStateToProps)(withTranslation()(Train)));
