import React, { Fragment, useEffect, useState } from "react";
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import { fetchIntents, fetchSlots, fetchTestModelList, fetchModelConfigList } from '../../../features/settings';
import { fetchModelFilters } from "../../../features/modelFilters";
import { setMessageState } from '../../../features/messageInfo';
import { deleteFilter } from "../../../features/filters";
import { getData, postData, deleteData, putData } from '../../../core/fetchService';
import { MESSAGE_STATUS, DIALOG_USER_STATE } from '../../../core/constants';
import EnhancedTable, { viewLink } from '../../components/projectTable';
import MuiIconButton from '@material-ui/core/IconButton';
import UpdateIcon from "@material-ui/icons/Update";
import DeleteIcon from '@material-ui/icons/Delete';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import ConfirmDialog from '../../components//confirmDialog'
import Tooltip from '@material-ui/core/Tooltip';
import GetAppIcon from "@material-ui/icons/GetApp";
import EditIcon from '@material-ui/icons/Edit';
import BlockIcon from "@material-ui/icons/Block";
import BrightnessHighIcon from "@material-ui/icons/BrightnessHigh";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  GlobalState, linkHeadCell, optionalLinkHeadCell,
  getRunConfigOption, CHK_FAIL, download, withTranslation,
  subtractObject, getProject, isGAI,
}                                                             from '../../../core/utils';
import ReportDialog, {
  DIFF_VIEW_ID, getTestModelIntentAcc, getTestModelSlotAcc,
  getTestModelWRR, getTestModelGenvAcc, VIEW_TYPES,
}                                                             from './ReportDialog'
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { useTranslation } from 'react-i18next';
import { withStyles } from "@material-ui/styles";
import CloseBar from "../../components/dialogCloseBar";
import {
  Table, TableBody, TableRow, TableCell,
} from "@material-ui/core";
import CircularProgress from '@material-ui/core/CircularProgress';

import IconButton from "../../components/material-ui/IconButton";
import { SimpleMenu } from "../../components/material-ui/Menu";

import {
  viewStatus, linkStyle, Runnable,
}                                           from "../../components/taskStatus";

import notBackdropClicked from "../../components/helpers/notBackdropClicked";
import Scrollable from '../../components/helpers/scrollable';

const viewTestStatus = (row, cell) => {
  return viewStatus(row, cell, {
    s_obj: row,
    percent: row.progress?.percent,
    in_progress: 'Test',
  });
};

// const useModal = () => {
//   const [isShowing, setIsShowing] = useState(false);

//   function toggle() {
//     setIsShowing(!isShowing);
//   }

//   return {
//     isShowing,
//     toggle,
//   }
// };

const styles = (theme) => ({
  dialogField: {
    margin: 8
  }
});

function EditDialog(props) {
  const { rowId, isClone, classes, testModelList, onClose } = props;
  const testmodel = (testModelList.testmodels || []).find(tm => tm._id == rowId) || {};
  const { name, description, dataset, model } = testmodel;
  const initData = { name, description, dataset, model };
  const [modelData, setModelData] = useState(initData);
  const scroll = 'paper';
  const { t } = useTranslation();

  const onCancel = () => onClose();

  return (
    <Dialog
      maxWidth="sm"
      fullWidth={true}
      open={true}
      onClose={notBackdropClicked(onCancel)}
      scroll={scroll}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
    >
      <CloseBar
        onClose={onCancel}
        title={rowId ? isClone ? t('tests.clone_test') : t('tests.edit_test') : t('tests.add_test')}
      />
      <DialogContent dividers={scroll === 'paper'} style={{overflow: 'hidden'}}>
        <TextField
          id="testName"
          required
          size="small"
          className={classes.dialogField}
          label={t('common.name')}
          onChange={(event, value) => setModelData({ ...modelData, name: event.target.value })}
          variant="outlined"
          fullWidth
          defaultValue={name || null}
        />
        <TextField
          id="testDescription"
          size="small"
          className={classes.dialogField}
          label={t('common.description')}
          onChange={(event, value) => setModelData({ ...modelData, description: event.target.value })}
          variant="outlined"
          multiline
          rows={2}
          fullWidth
          defaultValue={description || null}
        />
        <Autocomplete
          required
          id="testSet"
          size="small"
          fullWidth
          options={(testModelList && testModelList.datasets) || []}
          onChange={(event, value) => setModelData({ ...modelData, dataset: value?._id || null })}
          getOptionLabel={(option) => option.name}
          defaultValue={(dataset && testModelList.datasets.find(d => d._id === dataset)) || null}
          renderInput={(params) => (
            <TextField {...params} className={classes.dialogField} variant="outlined" label={t('tests.test_set')} />
          )}
        />
        <Autocomplete
          required
          id="model"
          fullWidth
          size="small"
          ListboxProps={{ style: { maxHeight: 120 } }}
          options={(testModelList && testModelList.models) || []}
          onChange={(event, value) => setModelData({ ...modelData, model: value?._id || null })}
          getOptionLabel={(option) => option.name}
          defaultValue={(model && testModelList.models.find(d => d._id === model)) || null}
          renderInput={(params) => (
            <TextField {...params} className={classes.dialogField} variant="outlined" label={t('tests.model')} />
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button color="primary" autoFocus
          onClick={onCancel}
        >
          {t('common.cancel')}
        </Button>
        <Button color="primary"
          disabled={!['name','model','dataset'].every(k => modelData[k])}
          onClick={() => onClose(subtractObject(modelData, initData))}
        >
          {isClone ? t('common.clone') : t('common.save')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

class Tests extends React.Component {
  constructor(props) {
    super(props);
    const { cm, ...V } = VIEW_TYPES;
    this.VIEW = V;
    this.state = {
      showModal: null,
      dialogTitle: null,
      modalContent: null,
      btnNameAgree: null,
      editDialogOpen: false,
      editClone: false,
      showJsonModal: false,
      showEntityTab: undefined,
      rowId: null,
      showConfirmStatus:false,
      modalContentStatus:null
    };

    this.scrollable = new Scrollable(this);
    this.runnable = new Runnable({
      getStatus: tm => tm?.status,
      statusName: 'Test',
      updateStatus: this.getTestModelList,
    });
    this.globalState = new GlobalState(this.updateGlobalState);
    this.eventSource = new EventSource(`/events`);
  }

  isGenerative = () => {
    return isGAI(getProject(this.props.projects, this.props.projectId));
  }

  getTestModelList = () => {
    this.props.dispatch(fetchTestModelList(this.props.projectId));
  };

  updateGlobalState = () => {
    this.props.dispatch(fetchIntents(this.props.projectId));
    this.props.dispatch(fetchSlots(this.props.projectId));
    this.getTestModelList();
    this.props.dispatch(deleteFilter(DIFF_VIEW_ID));
  };

  componentDidMount() {
    this.globalState.componentDidMount();
    this.scrollable.componentDidMount();

    this.eventSource.addEventListener('message', event => {
      this.getTestModelList();
    });

    this.props.dispatch(fetchModelConfigList(this.props.projectId));
    this.props.dispatch(fetchModelFilters(this.props.projectId));
  }

  componentDidUpdate(prevProps) {
    this.runnable.componentDidUpdate(this.props.testModelList?.testmodels);
    this.globalState.componentDidUpdate(prevProps, this.props);
  }

  componentWillUnmount() {
    this.runnable.componentWillUnmount();

    if(this.eventSource){
      this.eventSource.close();
    }
    this.abortController.abort();
  }

  abortController = new window.AbortController();

  getTestModel = id => (this.props.testModelList?.testmodels || []).find(tm => tm._id == id);

  hasResults = (id, view) => {
    const tm = this.getTestModel(id);
    const y = {
      intents:  getTestModelIntentAcc(tm),
      slots:    getTestModelSlotAcc(tm),
      asr:      getTestModelWRR(tm),
      genv:     getTestModelGenvAcc(tm),
    };
    const defined = v => v != undefined;
    return view ? defined(y[view]) : Object.values(y).some(defined);
  };

  handleExportRow = id => () => {
    this.setState({
      rowId: id,
    });
  };

  handleExportMenuClose = fmt => {
    if (!fmt)
      return;
    const { dispatch } = this.props;
    const { rowId } = this.state;
    const tm = this.getTestModel(rowId);
    const USE_BE_FILENAME = undefined;

    if (fmt.slice(0,4) == 'json' || this.isGenerative())
      return download(`/api/testmodel/${rowId}/download_results?format=${fmt}`, dispatch, USE_BE_FILENAME);

    download('/api/datasetrow', dispatch, USE_BE_FILENAME, {
      dataset: tm.dataset,
      view: fmt.replace(/_csv$/, ''),
      asr_diff_2: fmt == 'asr_csv' && !getRunConfigOption('useCommonDiffForASR'),
      apply_model_filters: tm.model,
      diff_testmodel_1: tm._id,
      download: true,
    });
  };

  handleEditRow = id => () => {
    this.setState({ editDialogOpen: true, rowId: id, editClone: false });
  };

  handleCloneRow = id => () => {
    this.setState({ editDialogOpen: true, rowId: id, editClone: true });
  };

  handleDeleteRow = (id, name) => () => {
    const { t } = this.props;
    this.setState({
      rowId: id,
      showModal: 'delete',
      dialogTitle: t('tests.delete'),
      modalContent: `${t('tests.delete_test_model')}: "${name}"?`,
      btnNameAgree: null,
    });
  };

  handleClickNewRow = () => {
    this.setState({ editDialogOpen: true, rowId: null });
  };

  handleCloseEditDialog = obj => {
    this.setState({ editDialogOpen: false });
    if (!obj)
      return;

    const { dispatch, projectId, t } = this.props;
    const { rowId, editClone } = this.state;

    (rowId
      ? editClone
        ? postData.bind(null, `/api/testmodel/copy/${rowId}`, { testmodel: { ...obj, project: projectId } })
        : putData.bind(null, `/api/testmodel/${rowId}`, { testmodel: obj })
      : postData.bind(null, `/api/testmodel`, { testmodel: { ...obj, project: projectId } })
    )(
      dispatch,
      data => {
        dispatch(setMessageState({
          snackBarMessages: t('tests.test_saved'),
          snackBarVariant: MESSAGE_STATUS.SUCCESS,
          snackBarState: true,
        }));
        this.getTestModelList();
      })
  };

  requestTestAction = (id, action) => {
    const { dispatch } = this.props;
    getData(`/api/testmodel/${id}/${action}`, dispatch, data => {
      // make blue and add time 15 sec
      data.message && dispatch(setMessageState({
        snackBarMessages: data.message,
        snackBarVariant: MESSAGE_STATUS.INFO,
        snackBarState: true,
        snackBarDuration: 15*1000,
      }));
      this.getTestModelList();
    });
  };

  handleTestStopClick = (id, action) => {
    const { t, testModelList } = this.props;
    if (action == 'test' && !this.hasResults(id))
      return this.requestTestAction(id, action);
    this.setState({
      showModal: action,
      rowId: id,
      dialogTitle: t('tests.title', { name: testModelList?.testmodels.find(tm => tm._id == id)?.name }),
      modalContent: t('tests.'+ action +'_question'),
    });
  };

  handleCloseModal = modalState => {
    const { showModal: action, rowId } = this.state;
    const { dispatch, testModelList } = this.props;
    this.setState({ showModal: null });
    if (modalState != DIALOG_USER_STATE.AGREE)
      return;
    if (['test','stop'].includes(action)) {
      this.requestTestAction(rowId, action);
    } else if (action == 'delete') {
      deleteData(`/api/testmodel/${rowId}`, dispatch, data => {
        this.getTestModelList();
      });
    } else {
      CHK_FAIL(`unknown model action: ${action}`);
    }
  };

  viewLinkWithUpdate = (row, cell) => {
    const { t } = this.props;
    const View = () => {
      const [clicked, setClicked] = useState(false);      
      useEffect(() => { !row.outdated && setClicked(false) }, [row.outdated]);
      return row.status == 'Ready' ? (
        <>
          {viewLink(row, cell)}
          {row.outdated ? (
            <Tooltip title={t("common.refresh")}>
              <MuiIconButton
                size="small"
                style={{marginLeft: 10, marginTop: -3}}
                onClick={() => { setClicked(true); this.testSetChanged(row, () => setClicked(false)) }}
              >
                {clicked ? <CircularProgress size="21px"/> : <UpdateIcon/>}
              </MuiIconButton>
            </Tooltip>
          ) : null}
        </>
      ) : null;
    };
    return <View/>
  };

  testSetChanged = (row, onError) => {
    postData(`/api/testmodel/${row._id}/testset_changed`, {}, this.props.dispatch, data => {
      this.getTestModelList();
    }, {
      disable_show_loading: true,
    })
      .then(data => data.error && onError && onError(data.error));
  };

  render() {
    const {
      showConfirmStatus, modalContentStatus,
      editDialogOpen, editClone, rowId, showJsonModal, showEntityTab,
      showModal, dialogTitle, modalContent, btnNameAgree,
    } = this.state;
    const { t, testModelList, classes, dispatch, intentsList, slotsList, datasets, modelConfigs, modelFilters } = this.props;
    const testIntentAccStyle = tm => tm.outdated ? {color: 'lightgrey'} : {};
    const is_asr = tm => (testModelList?.models || []).find(m => m._id == tm.model)?.type == 'ASR_E2E';
    const has = type => (testModelList?.testmodels || []).some(tm => type == 'asr' ? is_asr(tm) : tm.data?.[type]);
    const all_asr = type => (testModelList?.testmodels || []).every(is_asr);
    const showReport = (showEntityTab = {}) => row => () => {
      if (row._id != rowId)
        dispatch(deleteFilter(DIFF_VIEW_ID));
      this.setState({ showJsonModal: true, ...showEntityTab, rowId: row._id });
    };

    const link = linkHeadCell;
    const link_o = optionalLinkHeadCell;

    const headCells = [
        { _id: 'name',        width: "auto",  label: t('common.name'),      textSearch: true },
        { _id: 'datasetname', width: "10%",   label: t('tests.test_set'),   filterOn: true,   ...link('datasets')  },
        { _id: 'model',       width: "10%",   label: t('tests.model'),      filterOn: true,   ...link('train')     },
        { _id: "trainconfig", width: "10%",   label: t("train.config"),     filterOn: true,   ...link_o('configs') },
        { _id: 'trainset',    width: "10%",   label: t('train.train_set'),  filterOn: true,   ...link_o('datasets') },
        { _id: 'filters',     width: "10%",   label: t('train.filters'),    filterOn: true,   ...link_o('filters') },
        { _id: 'date',        width: "10%",   label: t('tests.date'),       dateTime: true   },
        {
          _id: 'status',      width: "10%",   label: t('tests.status'),   link: row => () => {
            const modalContentStatus = (
              <Table size="small">
                <TableBody>
                  {row.error && <TableRow>
                    <TableCell style={{width: '200px'}}>{t('tests.test_status')}</TableCell>
                    <TableCell>{row.error}</TableCell>
                  </TableRow>}
                  <TableRow>
                    <TableCell>{t('tests.test_started')}</TableCell>
                    <TableCell><span style={{textDecoration:'underline'}}>{row.startTestAt}</span></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{t('tests.test_finished')}</TableCell>
                    <TableCell><span style={{textDecoration:'underline'}}>{row.finishTestAt}</span></TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            );
            this.setState({ showConfirmStatus: true, modalContentStatus: modalContentStatus });
          },
          style: linkStyle(row => [row]),
        },
        ...(has('int') && !all_asr() && !has('genv') ? [
        {
          _id: 'intent',      width: "10%",   label: t('tests.intents_acc'),  link: showReport({ showEntityTab: false }),

          align: 'left',
          style: testIntentAccStyle,
        }] : []),
        ...(has('ent') ? [
        {
          _id: 'entity',      width: "10%",   label: t('tests.entities_f1'),  link: showReport({ showEntityTab: true }),
        }] : []),
        ...(has('asr') ? [
        {
          _id: 'asr',         width: "15%",   label: t('tests.asr_WRR'),      link: showReport(),
        }] : []),
        ...(has('genv') ? [
        {
          _id: 'genv_score',  width: "15%",   label: t('tests.bleu_score'),   link: showReport(),
        }] : []),
      ];

    const model = r => testModelList.models.find(d => d._id == r.model);

    function addModelInfo(m) {
      if (!m)
        return {};
      const ds_info = i => {
        const d = (datasets || []).find(d => d._id == i);
        return { 
          _id: d?._id,
          name: !d ? '???' : d.stat ? d.name +'::'+ d.stat.size : d.name
        };
      };
      const trainconfig = (modelConfigs || []).find(mc => mc._id == m.model_config);
      const trainset = m.trainSet.map(ds_info);
      const filters = (m.filters || []).map(f => (modelFilters || []).find(mf => mf._id == f.filter) || {name: '...'});
      return { trainset, trainconfig, filters };
    }

    const tableID = "tests";

    return (
      <Fragment>
        {showModal &&
          <ConfirmDialog
            title={dialogTitle}
            content={modalContent}
            closeModal={this.handleCloseModal}
            btnNameAgree={btnNameAgree}
          />}
        {showConfirmStatus &&
          <ConfirmDialog
            open={true}
            content={modalContentStatus}
            showBtnNameAgree={false}
            title={t('common.info')}
            btnNameDisagree={t('common.close')}
            closeModal={() => this.setState({showConfirmStatus:false})}
          />}
        {editDialogOpen && <EditDialog
          rowId={rowId}
          isClone={rowId && editClone}
          classes={classes}
          testModelList={testModelList}
          onClose={this.handleCloseEditDialog}
        />}
        {showJsonModal &&
          <ReportDialog {...{
            testModelId: rowId,
            showEntityTab,
            testModelList,
            intentsList,
            slotsList,
            onClose: () => { this.setState({ showJsonModal: false }) },
            testSetChanged: this.testSetChanged.bind(this),
            isGenerative: this.isGenerative(),
          }} />}
        {testModelList && Object.keys(testModelList).length > 0 &&
          <EnhancedTable
            id={tableID}
            useLocationSearch
            headCells={headCells}
            rows={testModelList.testmodels.map(r => ({
              ...r,
              datasetname: testModelList.datasets.find(d => d._id == r.dataset),
              model: model(r),
              ...addModelInfo(model(r)),
              date: r.status == 'Ready' && r.finishTestAt ? r.finishTestAt : r.updatedAt,
              intent: getTestModelIntentAcc(r),
              entity: getTestModelSlotAcc(r),
              asr: getTestModelWRR(r),
              genv: getTestModelGenvAcc(r),
              finishTestAt: r.finishTestAt && new Date(r.finishTestAt).toLocaleString(),
              startTestAt: r.startTestAt && new Date(r.startTestAt).toLocaleString(),
              error: r.error?.json?.message || r.error?.error || r?.error
            }))}
            viewCell={{ status: viewTestStatus, intent: this.viewLinkWithUpdate, genv_score: this.viewLinkWithUpdate }}
            toolBarName={t('tests.test_results')}
            newRowTitle={t("tests.add_test")}
            handleClickNewRow={this.handleClickNewRow}
            handleClickUpdateRow={this.getTestModelList}
            checkBoxTableCell={getRunConfigOption('noIDColumn')?.[tableID] ? undefined : (id, name, index) => (
               <TableCell>{index + 1}</TableCell>
            )}
            customBtns={(name, id) => {
              const isActive = this.runnable.isActive(testModelList?.testmodels, id);
              const ICONS = [
                ['edit',      this.handleEditRow,       EditIcon],
                ['clone',     this.handleCloneRow,      FileCopyIcon],
                ['export'     /*            inlined               */],
                ['delete',    this.handleDeleteRow,     DeleteIcon],
              ];
              const available_choices = Object.values(this.VIEW).filter(k => this.hasResults(id, k));
              const export_choices = {
                ...(!available_choices.includes('asr') && !this.isGenerative() ? { json_rasa: 'JSON.RASA' } : {}),
                ...(this.isGenerative() ? { json: 'JSON' } : {}),
                /* html: 'HTML', */
                ...Object.fromEntries(available_choices.map(k => [k +'_csv', 'CSV' + (this.isGenerative() ? '' : '.'+ t('tests.'+k))])),
              };
              return (
                <div style={{width: "25%", display: "flex"}}>
                  {!getRunConfigOption('trainTestIcons') ?
                    <Button variant="outlined" size="small"
                      onClick={() => this.handleTestStopClick(id, !isActive ? 'test' : 'stop')}
                    >
                      {t(!isActive ? 'tests.test' : 'common.stop')}
                    </Button>
                    :
                    <IconButton
                      onClick={() => this.handleTestStopClick(id, !isActive ? 'test' : 'stop')}
                      title={t(!isActive ? 'tests.test' : 'common.stop')}
                      Icon={isActive ? BlockIcon : BrightnessHighIcon}
                    />
                  }
                  <div style={{marginLeft: 5, display: 'flex'}}>
                    {ICONS.map((i,j) => j == 2
                      ? <SimpleMenu
                        key={i[0]}
                        Icon={GetAppIcon}
                        choices={export_choices}
                        title={t("common.export")}
                        handleOpen={this.handleExportRow(id, name)}
                        handleClose={this.handleExportMenuClose}
                        disabled={!this.hasResults(id)}
                      />
                      : <IconButton key={i[0]} title={t("common."+ i[0])} onClick={i[1](id, name)} Icon={i[2]}/>
                    )}
                  </div>
                </div>
              )}}
          />
        }
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  projects:       state.settings.projects,
  projectId:      state.settings.projectId,
  testModelList:  state.settings.testModelList,
  intentsList:    state.settings.intentsList,
  slotsList:      state.settings.slotsList,
  datasets:       state.settings.projectDatasets,
  modelConfigs:   state.settings.modelConfigList,
  modelFilters:   state.modelFilters.filters,
});

export default withRouter(connect(mapStateToProps)(withStyles(styles)(withTranslation()(Tests))));
