import React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import { withStyles } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import TableCell from "@material-ui/core/TableCell";
import Dialog from "@material-ui/core/Dialog";
import CloseBar from "../../components//dialogCloseBar";
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from "@material-ui/core/DialogContent";

import MultiSelect  from "../../components/material-ui/MultiSelect";
import Autocomplete from "../../components/material-ui/Autocomplete";
import TextField    from "../../components/material-ui/TextField";
import NumberField  from "../../components/material-ui/NumberField";

import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
} from "@material-ui/core";
import {
  getData,
  postData,
  putData,
} from "../../../core/fetchService";
import ConfirmDialog from "../../components/confirmDialog";
import { IsAdmin, getRunConfigOption, filterKeys, filterName } from "../../../core/utils";

import { PROJECT_TYPE_AC_OPTS } from "../../../core/constants";

import notBackdropClicked from "../../components/helpers/notBackdropClicked";

import { SettingsMenu } from "../../pages/dashboard/SettingsDialog";

const styles = (theme) => ({
  textField: {
    marginTop: 12,
    flexGrow: 1,
  },
  gridList: {
    overflow: "hidden",
    padding: 5,
  },
  summary: {
    marginLeft: "20px",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
  },
  tdTopCell: {
    borderTop: "1px solid #000",
  },
});

class EditProject extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      project: {},
      showModal: false,
      modalContent: "",
    };
  };

  componentDidMount() {
    const { id, dispatch } = this.props;
    !id && getData('/api/project/named_configs', dispatch, data => {
      this.setState({ project: { ...this.state.project, ...data } });
    });
    id && this.getProjectData();
  };

  user_groups = [];

  getProjectData = async () => {
    const { id, dispatch } = this.props;

    if (IsAdmin())
      await getData(`/api/user_group`, dispatch, data => { this.user_groups = data.user_groups });

    const res = await getData(`/api/project/${id}`, dispatch, data => {
      const access = (IsAdmin() && data.project.access || [])
        .map(i => this.user_groups.find(g => g._id == i) || { name: `--Not-Found-!--(${i})` });

      this.setState({ __ready: true, project: { ...data.project, access } });
    });
  };

  handleSetProjectData = (name, is_autocomplete) => (event, new_value) => {
    const value = is_autocomplete ? new_value?.value || null : event.target.value;
    this.setState({ project: { ...this.state.project, [name]: value } });
  };

  handleUpdateProject = () => {
    const { projects, id, dispatch, handleCloseEdit, onCreateOrUpdate } = this.props;
    const FIELDS = 'name type description access nluConfig similarityConfig asrConfig composerConfig'.split(' ');
    const project = filterKeys(this.state.project, FIELDS); // TODO better filterOutKeys
    project.access = project.access?.map(g => g._id);

    (id? putData: postData)(`/api/project/${id || ''}`, {
      project,
    }, dispatch, data => {
      onCreateOrUpdate(data.project._id);
    });

    handleCloseEdit(true);
  };
  
  showStatisticsDialog = () => {
    const { classes, t, id } = this.props;

    getData(`/api/project/${id}/stats`, this.props.dispatch, data => {
      this.setState({
        showModal: true,
        modalContent: (
          <div className={classes.summary}>
            <Grid container spacing={2}>
              <Grid item xs={5}>
                <GridList className={classes.gridList} cols={2}>
                  {[
                    t("projects.intent"),
                      "intentCount",
                      t("projects.slots"),
                      "slotCount",
                      t("projects.entities"),
                      "entityCount",
                  ].map((v, idx) => (
                    <GridListTile style={{ height: "auto" }} key={idx}>
                      <span>
                        {(() => {
                          if (idx%2 <= 0) return v;
                          return data[v] || 0;
                        })()}
                      </span>
                    </GridListTile>
                  ))}
                </GridList>
              </Grid>
              <Grid item xs={7}>
                <TableContainer>
                  <Table
                    stickyHeader
                    size="small"
                    aria-label="a dense table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell align="center">
                          {t("projects.entries")}
                        </TableCell>
                        <TableCell align="center" colSpan={2}>
                          {t("projects.annotated")}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableHead>
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell align="center">
                          {t("projects.manual")}
                        </TableCell>
                        <TableCell align="center">
                          {t("projects.auto")}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data?.datasets.map((ds, idx) => (
                        <TableRow key={idx}>
                          <TableCell component="th" scope="row">
                            {ds.name}
                          </TableCell>
                          <TableCell align="center">
                            {ds.rowCount || 0}
                          </TableCell>
                          {/* place manual */}
                          <TableCell align="center">
                            {ds.rowManualCount || 0}
                          </TableCell>
                          {/* place auto */}
                          <TableCell align="center">
                            {ds.rowAutoCount || 0}
                          </TableCell>
                        </TableRow>
                      ))}
                      <TableRow>
                        <TableCell
                          component="th"
                          scope="row"
                          className={classes.tdTopCell}
                        >
                          {t("common.all")}
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.tdTopCell}
                        >
                          {data?.rowCount}
                        </TableCell>
                        {/* place sum by manual */}
                        <TableCell
                          align="center"
                          className={classes.tdTopCell}
                        >
                          {data?.rowManualCount}
                        </TableCell>
                        {/* place sum by auto */}
                        <TableCell
                          align="center"
                          className={classes.tdTopCell}
                        >
                          {data?.rowAutoCount}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </div>
        ),
      });
    });
  };

  onCancel = () => {
    this.props.handleCloseEdit()
  };

  render() {
    const { id, classes, t } = this.props;
    const { project, showModal, modalContent } = this.state;

    if (id && !this.state.__ready) // prevent filling default values (of TextFields, f.e.) with empties
      return null;
    return (
      <Dialog
        onClose={notBackdropClicked(() => this.onCancel())}
        open={true}
        fullWidth
        maxWidth='md'
      >
        <CloseBar onClose={this.onCancel} title={id? t('projects.edit_project'): t('projects.add_new_project')}/>
        <DialogContent>
          {showModal && (
            <ConfirmDialog
              maxWidth="sm"
              open={true}
              btnNameDisagree={t("common.close")}
              showBtnNameAgree={false}
              title={t("train.summary")}
              content={modalContent}
              closeModal={() => { this.setState({ showModal: false }) }}
            />)}
          <div style={{display: 'flex'}}>
            <TextField
              id="projectName"
              label={t("common.name")}
              required
              className={classes.textField}
              value={project.name}
              onChange={filterName(this.handleSetProjectData('name'))}
              autoFocus
            />
            <Autocomplete
              label={t("common.type")}
              required
              className={classes.textField}
              style={{marginLeft: 10, width: '30%'}}
              fullWidth
              options={PROJECT_TYPE_AC_OPTS}
              getOptionLabel={option => option.name}
              value={PROJECT_TYPE_AC_OPTS.find(o => o.value == (project.type || id && 'NLU')) || null}
              onChange={this.handleSetProjectData("type", true)}
              disabled={Boolean(id)}
            />
            <Button
              style={{marginTop: 10, marginLeft: 10, width: 200}}
              disabled={!id}
              variant="contained"
              color="primary"
              onClick={() => this.showStatisticsDialog()}
            >
              {t("common.statistics")}
            </Button>
          </div>
          <TextField
            id="projectDescription"
            label={t("common.description")}
            className={classes.textField}
            multiline
            rows="2"
            defaultValue={project.description}
            onChange={this.handleSetProjectData("description")}
          />
          {IsAdmin() && getRunConfigOption('enableRoleModel') ? (
            <MultiSelect
              id="access"
              className={classes.textField}
              defaultValue={project.access}
              onChange={this.handleSetProjectData("access", true)}
              options={this.user_groups}
              getOptionLabel={option => option.name}
              label={t("projects.access")}
            />) : null}
          <SettingsMenu
            settings={project}
            setSettings={s => this.setState({ project: s })}
            style={{marginTop: 10}}
          />
        </DialogContent>
        <DialogActions>
          <Button disabled={!project.name || !id && !project.type} onClick={() => this.handleUpdateProject()}>
            {t("common.save")}
          </Button>
          <Button onClick={this.onCancel}>
            {t("common.cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const mapStateToProps = (state) => ({
  projects: state.settings.projects
});

export default connect(mapStateToProps)(
  withStyles(styles)(withTranslation()(EditProject)),
);
