import React, { useEffect, useState, useRef } from "react";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { useTranslation } from "react-i18next";
import Toolbar from "@material-ui/core/Toolbar";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Button, Grid, Typography } from "@material-ui/core";
import EnhancedTable from "../../components/projectTable";
import Checkbox from "@material-ui/core/Checkbox";
import TableCell from "@material-ui/core/TableCell";
import { getData, putData, fetchAll } from "../../../core/fetchService";
import { connect, useDispatch } from "react-redux";
import { withRouter } from "react-router";
import { makeStyles, createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import GridListTile from "@material-ui/core/GridListTile";

import NumberField from "../../components/material-ui/NumberField";

import { cmpPossibleNumbers, groupById } from "../../../core/utils";

const useStyles = makeStyles(theme => ({
  gridItem: {
    //margin: '20px auto'
  },
  tableAppBar: {
    width: "100%",
    minWidth: 750,
  },
  tableToolbarField: {
    // setting just width: 100 does not work for some reason
    minWidth: "200px",
    maxWidth: "200px",
    margin: "0 10px",
  },
  tableToolbarButton: {
    width: "60%",
    margin: "0 10px",
  },
  spacer: {
    flexGrow: 1,
  },
  gridRoot: {
    '&:focus-visible': {
      outline: 'none',
    }
  }
}));

/* FIXME: did not work out */
const TableCellTheme = createMuiTheme({
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          background: 'red',
          borderBottom: 'none',
        }
      }
    }
  }
});

const Similarity = ({
  open,
  currentRow,
  dataset,
  scrollable,
  projectId,
  intentsList,
  handleChangeAnnotation,
  onClose,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const similarityConfig = dataset?.similarityConfig;

  const [partition, setPartition] = useState(similarityConfig?.partition);
  const [clusterPartitions, setClusterPartitions] = useState([]);
  const [simThreshold, setSimThreshold] = useState(similarityConfig?.threshold || 0.5);
  const [allChecked, setAllChecked] = useState(false);
  const [maxCount, setMaxCount] = useState(similarityConfig?.max);
  const [clusterId, setClusterId] = useState();
  const [rows, setRows] = useState([]);
  const [selectedRowsIds, setSelectedRowsIds] = useState([]);
  const [filteredRowsIds, setFilteredRowsIds] = useState([]);
  const [selectedIntentId, setSelectedIntentId] = useState(open && currentRow.intentId);
  const intentsComboData = (intentsList || [])
    .map(({ _id, name, description }) => ({ value: _id, label: name, title: description }));
  const headCells = [
    {
      _id: "intentId",
      label: t("annotation.intent"),
      width: "35%",
      comboData: intentsComboData,
      disabled: row => selectedRowsIds.includes(row._id),
      filterOn: true,
    },
    {
      _id: "cluster_id",
      label: t("annotation.cluster"),
      width: "10%",
      filterOn: true,
    },
    {
      _id: "similarity",
      label: t("annotation.sim"),
      width: "10%",
    },
    {
      _id: "text",
      label: t("annotation.utterance"),
      width: "40%",
      textSearch: true,
    },
  ];
  const selectedIntent = intentsComboData.find(i => i.value === selectedIntentId);

  const getPartitions = () => {
    const { server, model } = similarityConfig || {};
    if (!server || !model)
      return;
    const url = `/api/settings/similarity_config_details?server=${server}`;

    getData(url, dispatch, data => {
      setClusterPartitions(data.similarity_config_details.models[model].cluster_partitions.sort(cmpPossibleNumbers));
      setPartition(similarityConfig.partition);
    });
  };

  const handleRowItemChange = async selected_rows => {
    await fetchAll(selected_rows.map(({ _id, intentId }) =>
      putData(`/api/dataset/${dataset._id}/row/${_id}`, { row: { intentId } }, dispatch)));
  
    setRows(rows.map(r => selected_rows.find(sr => sr._id == r._id) || r));
  };

  const handleRowChecked = (rowId, checked) => {
    setSelectedRowsIds(checked ? [...selectedRowsIds, rowId] : selectedRowsIds.filter(id => id !== rowId));
  };

  const handleALlRowsChecked = (isChecked) => {
    setAllChecked(isChecked);
    const is_filtered = r => !filteredRowsIds || filteredRowsIds[r._id];
    isChecked ? setSelectedRowsIds(rows.filter(is_filtered).map(r => r._id)) : setSelectedRowsIds([]);
  };

  const getSimilarRows = () => {
    const query = new URLSearchParams();
    query.append("max", maxCount);
    query.append("threshold", simThreshold);
    if (partition)
      query.append("partition", partition);
    const url = `/api/dataset/${dataset._id}/row/${currentRow._id}/get_similar_rows?${query}`;

    getData(url, dispatch, data => {
      const get_sim_value = item => Number(item.similarity).toFixed(4);
      const get_cluster_id = item => item.cluster?.[0]?.id ?? '';

      setClusterId(get_cluster_id(data));
      setRows(data.similar.sort((a, b) => b.similarity - a.similarity).map(item => {
        return {
          ...item,
          similarity: get_sim_value(item),
          cluster_id: get_cluster_id(item),
        };
      }));
    }, { t, message_prefix: 'settings' });
  };

  const node = useRef(null);

  useEffect(() => { open && getPartitions() },                            [dataset]);
  useEffect(() => { open && getSimilarRows() },                           [currentRow?.id]);
  useEffect(() => { open && setSelectedIntentId(currentRow.intentId) },   [currentRow?.id]);
  useEffect(() => { open && node.current.focus() },                       []);
  useEffect(() => { scrollable.componentDidMount() },                     []);

  const applyIntent = intentId => {
    setSelectedIntentId(intentId);
    setRows(rows.slice());
    handleRowItemChange(rows.filter(row => selectedRowsIds.includes(row._id))
                        .map(row => ({ ...row, intentId })));
    handleChangeAnnotation({ ...currentRow, intentId });
  };

  const tc_root = { borderBottom: 'none', paddingBottom: 20 };

  return open ? (
    <Grid container spacing={2} className={classes.gridRoot} ref={node} tabIndex="0"
      onKeyUp={e => e.key == 'Escape' && onClose()}
    >
      <Grid container item xs={12} justify="flex-start">
        <Toolbar className={classes.tableAppBar}>
          <Tooltip size="small" title={t("common.back")} style={{ marginBottom: "10px" }}>
            <IconButton size="small"
                        onClick={onClose}>
              <ArrowBackIcon size="small"/>
            </IconButton>
          </Tooltip>
          <div className={classes.spacer}>
            &nbsp;
          </div>
          <div style={{ display: "flex" }}>
            {clusterPartitions?.length ?
            <Autocomplete
              size="small"
              className={classes.tableToolbarField}
              options={clusterPartitions}
              getOptionLabel={(option) => option}
              variant="outlined"
              value={partition || null}
              renderInput={(params) =>
                <TextField
                  {...params}
                  label={t("projects.cluster_partitions")}
                  variant="outlined"/>
              }
              onChange={(event, value) => setPartition(value)}
            /> : null}
            <NumberField
              value={simThreshold || ''}
              className={classes.tableToolbarField}
              adjustValue={v => v || 0}
              onChange={event => setSimThreshold(event.target.value)}
              inputProps={{ min: 0, max: 1, step: '.1' }}
              label={t("annotation.sim_threshold")}
              title={t("settings.sim_threshold")}
            />
            <NumberField
              value={maxCount || ''}
              className={classes.tableToolbarField}
              adjustValue={v => v ? Math.floor(v) : null}
              inputProps={{ min: 0 }}
              onChange={event => setMaxCount(event.target.value)}
              label={t("annotation.sim_limit")}
              title={t("settings.sim_limit")}
            />
            <Button fullWidth variant="outlined" className={classes.tableToolbarButton}
              onClick={getSimilarRows}
            >
              {t("common.apply")}
            </Button>
          </div>
        </Toolbar>
      </Grid>
      <Grid container item xs={12} spacing={2} direction="row" justify="center" alignItems="stretch">
        <TableContainer style={{ width: "100%", marginBottom: 10, padding: 0 }}>
          <Table size="small">
            <TableBody>
              <TableRow>
                <MuiThemeProvider theme={TableCellTheme}>{/* FIXME: does not work, using tc_root instead */}
                  <TableCell style={{ padding: "0 12px 0 16px", width: "5%", ...tc_root }}>
                    <Checkbox
                      style={{ width: "24px", height: "24px" }}
                      onChange={(event, checked) => handleALlRowsChecked(checked)}
                    />
                  </TableCell>
                </MuiThemeProvider>
                <TableCell style={{ width: "35%", ...tc_root }}>
                  <Tooltip title={selectedIntent?.description ?
                    <h3>{selectedIntent?.description}</h3> : ''}>
                    <Autocomplete
                      fullWidth
                      disableClearable={!!allChecked}
                      className={classes.gridItem}
                      size="small"
                      autoComplete
                      options={intentsComboData}
                      value={selectedIntent || null}
                      onChange={(_event, value) => applyIntent(value ? value.value : null)}
                      onKeyUp={event => event.key == 'Enter' && applyIntent(selectedIntentId)}
                      getOptionLabel={option => option.label}
                      renderOption={(option, state) =>
                        <Typography selected={state.selected}>
                          <Tooltip title={<h3>{option.title || '---'}</h3>}>
                            <span>{option.label}</span>
                          </Tooltip>
                        </Typography>
                      }
                      renderInput={(params) => {
                      const newParams = { ...params, InputProps: { ...params.InputProps, style: { fontSize: "0.875rem" } } };

                      return <TextField {...newParams} variant="outlined"/>
                      }}
                    />
                  </Tooltip>
                </TableCell>
                <TableCell style={{ width: "10%", ...tc_root }}>
                  {clusterId}
                </TableCell>
                <TableCell style={{ width: "10%", ...tc_root }}>
                  &nbsp;
                </TableCell>
                <TableCell style={{ width: "40%", padding: "0 12px 0 16px", ...tc_root }}>
                  <Typography style={{fontSize: "0.875rem"}}>
                    {currentRow.text}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <EnhancedTable
        id="sim"
        headCells={headCells}
        rows={rows}
        stickyState={false}
        toolBarName={null} // no toolbar
        handleRowItemChanged={row => handleRowItemChange([row])}
        checkBoxTableCell={rowId => (
          <TableCell padding="checkbox">
            <Checkbox checked={selectedRowsIds.includes(rowId)} onChange={(event, checked) => {
              handleRowChecked(rowId, checked);
            }}/>
          </TableCell>
        )}
        handleFilteredRows={f_rows => setFilteredRowsIds(f_rows && groupById(f_rows))}
      />
    </Grid>
  ) : null;
};

const mapStateToProps = state => ({
  projectId: state.settings.projectId,
  intentsList: state.settings.intentsList,
});

export default withRouter(connect(mapStateToProps)(Similarity));
