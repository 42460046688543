import React, { useState, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { getProjectId, getProjects } from "../../../features/settings";
import { fetchModelFilters } from "../../../features/modelFilters";
import { setMessageState } from "../../../features/messageInfo";
import { getData, postData, putData } from "../../../core/fetchService";
import { MESSAGE_STATUS, BTN, NLU_TYPE_AC_OPTS } from "../../../core/constants";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core";
import YAML from "yaml";
import { EditConfig } from "./editConfig";
import { Controlled as CodeMirror } from "react-codemirror2";
import CloseBar from "../../components/dialogCloseBar";
import MultiSelect from "../../components/material-ui/MultiSelect";
import {
  useTranslation, showInfo, showError, ucfirst, getRunConfigOption, filterName,
  getProject, isProperType, isProperRuntimeType, isGAI,
} from "../../../core/utils";
import notBackdropClicked from "../../components/helpers/notBackdropClicked";
import ConfirmDialog from "../../components/confirmDialog";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import IconButton from "../../components/material-ui/IconButton";

import { BackendSettings } from "../../pages/dashboard/SettingsDialog";

const NLUTypes = {
  NLU_RASA: "nluConfig",
  NLU_NB: "nluConfig",
  ASR_E2E: "nluConfig",
};

const useStyles = makeStyles(theme => {
  const fieldset = {
    margin: "10px",
    padding: "20px",
    borderRadius: 5,
    borderWidth: 1,
  };
  const dialogFieldMargin = {
    margin: 8,
  };
  const dialogFieldPadding = {
    paddingRight: 17,
  };
  return {
    _fieldset: {
      ...fieldset,
    },
    fieldset: {
      ...fieldset,
      color: "#C4C4C4",
    },
    dialogFieldMargin,
    dialogField: {
      ...dialogFieldMargin,
      ...dialogFieldPadding,
    },
    dialogInnerField: dialogFieldPadding,
    legend: {
      color: "#757575"
    },
    checkBox: {
      marginLeft: 10,
    },
    inlineFloatRightIcon: {
      float: "right",
      marginLeft: "20px",
      marginTop: "-25px",
      marginRight: "-15px",
      visibility: "hidden",
      '*:hover > &': {
        visibility: "visible",
      },
    },
    textFieldMessage: {
      marginTop: -5,
      marginBottom: 13,
    },
    errorMessage: {
      color: 'red',
    },
    runtimeModelStatus: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      color: 'initial',
    },
    runtimeModelButtons: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    checkSampleFirstRow: {
      display: 'flex',
      flex: 1,
      flexDirection: 'row',
      margin: '0 10px',
    },
  }
});

const useModalEditConfig = () => {
  const [isShowingEditConfig, setIsShowingEditConfig] = useState(false);

  function toggleEditConfig() {
    setIsShowingEditConfig(!isShowingEditConfig);
  }

  return {
    isShowingEditConfig,
    toggleEditConfig,
  };
};

function EditDialog(props) {
  const toType = ext_type => ext_type?.replace(/_Runtime/, '');
  const toExtType = type => type +'_Runtime';

  const EXT_TYPES = ['NLU_NB', 'ASR_E2E'];
  const EXT_TYPE_OPTS = EXT_TYPES.map(t => ({ name: t, value: toExtType(t) }));

  const classes = useStyles();
  
  const projectId = useSelector(getProjectId);
  const projects = useSelector(getProjects);
  const project = getProject(projects, projectId);

  const filterInternalModelTypeACOpts = (project, ac_opts) => ac_opts.filter(o => isProperType(project, o.value));
  const filterExternalModelTypeACOpts = (project, ac_opts) => ac_opts.filter(o => isProperRuntimeType(project, o.value));
  const internalModelTypeACOpts = filterInternalModelTypeACOpts(project, NLU_TYPE_AC_OPTS);
  const externalModelTypeACOpts = filterExternalModelTypeACOpts(project, EXT_TYPE_OPTS);
  const getACValue = (opts, value) => opts.length == 1 ? opts[0] : value;

  const is_external_init = false;
  const ext_type_init = is_external => is_external ? getACValue(externalModelTypeACOpts)?.value : undefined;
  const type_init = is_external => is_external
    ? toType(ext_type_init(is_external)) : getACValue(internalModelTypeACOpts)?.value;

  const initialEditModel = {
    project: projectId,
    name: null,
    description: '',
    is_external: is_external_init,
    trainSet: [],
    nluConfigText: null,
    nlu_train_url: null,
    nlu_deploy_url: null,
    model_config: null,
    ext_type: ext_type_init(is_external_init),
    type: type_init(is_external_init),
    include_data_schema: true,
    check_dupes: false,
    remote_name: '',
    runtime_name: '',
  };

  const MISSING = { missing: true };

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { _id, editModel, isClone, modelFilters, modelConfigs, onClose } = props;

  const defaultRuntimeName = editModel => (editModel.remote_name || '').replace(/__u\p{Hex_Digit}{7}$/u, '');
  const assignRuntimeName = editModel =>
    isClone ? { runtime_name: undefined }
      : editModel.runtime_name == undefined ? { runtime_name: defaultRuntimeName(editModel) }
      : {};

  const _editModel = editModel && {
    ...editModel,
    ...assignRuntimeName(editModel),
  };

  const [selectedConfig, setSelectedConfig] = useState(null);
  const [isPreviewConfigOpen, setIsPreviewConfigOpen] = useState(false);
  const [projectDataSets, setProjectDataSets] = useState(null);
  const [editModelParams, setEditModelParams] = useState(_editModel || initialEditModel);
  const [configDefaults, setConfigDefaults] = useState();
  const [trainServerInfo, setTrainServerInfo] = useState({});
  const [showCheckSample, setShowCheckSample] = useState(false);
  const [checkSampleText, setCheckSampleText] = useState("");
  const [checkSampleInProgress, setCheckSampleInProgress] = useState(false);
  const [checkSampleResult, setCheckSampleResult] = useState();
  const [_runtimeModelInfo, setRuntimeModelInfo] = useState(MISSING);

  const { isShowingEditConfig, toggleEditConfig } = useModalEditConfig();

  const _rti = _runtimeModelInfo;
  const runtimeModelInfo =  { ..._rti, date: _rti.date ? new Date(_rti.date).toLocaleString() : '???' };

  const is_nb = ['NLU_RASA', 'NLU_NB'].includes(editModelParams.type);
  const is_ext_nb = editModelParams.ext_type == 'NLU_NB_Runtime';
  const is_ext_asr = editModelParams.ext_type == 'ASR_E2E_Runtime';

  function fetchData() {
    getData(`/api/dataset?project=${projectId}`, dispatch, ({ datasets }) => {
      datasets && setProjectDataSets(datasets);
    });
  };

  useEffect(() => {
    updateExtModelList(undefined, true);
    getConfigDefaults();

    dispatch(fetchModelFilters(projectId));
  }, []);

  useEffect(() => {
    if (!projectDataSets) {
      fetchData();
    }
  }, [projects, _id]); // eslint-disable-line react-hooks/exhaustive-deps

  const EMPTY_EXT_MODEL_LIST = { models: [] };

  const [savedValue, setSavedValue] = useState();
  const [extModelList, setExtModelList] = useState(EMPTY_EXT_MODEL_LIST);

  const not_available = Boolean(extModelList.error || runtimeModelInfo.missing || !editModelParams.runtime_name);

  const updateExtModelList = async (server_url, no_error_popup) => {
    const is_ext = editModelParams.is_external;
    if (!is_ext && !is_nb || is_ext && !is_ext_nb)
      return;
    server_url = server_url || (is_ext ? editModelParams.nlu_train_url : editModelParams.nlu_deploy_url);
    let d = [];
    if (server_url) {
      const url = `/api/runtime/list_models?server=${server_url}&type=${editModelParams.type}`;
      const r = await getData(url, dispatch, data => {
        d = data;
        setExtModelList(data ? { models: data } : EMPTY_EXT_MODEL_LIST);
      }, {
        on_error: e => { if (no_error_popup) throw e },
      }).catch(e => {
        console.error(e);
        return { error: e };
      });
      if (r.error) {
        d = [];
        setExtModelList({ error: r.error.message });
      }
    }
    if (is_ext) {
      if (!d.includes(editModelParams.remote_name))
        setEditModelParams({ ...editModelParams, remote_name: '' });
    } else {
      updateRuntimeModelInfo(no_error_popup);
    }
  };

  const updateRuntimeModelInfo = no_error_popup => {
    const { nlu_deploy_url, runtime_name } = editModelParams;
    if (!nlu_deploy_url || !runtime_name)
      return;
    const url = `/api/runtime/model_info?server=${nlu_deploy_url}&name=${runtime_name}`;
    getData(url, dispatch, setRuntimeModelInfo, {
      on_error: e => { if (no_error_popup) throw e },
    }).catch(e => {
      console.error(e);
      setRuntimeModelInfo(MISSING);
    });
  }

  const handleUnloadModel = () => {
    const { nlu_deploy_url, runtime_name } = editModelParams;
    const url = `/api/runtime/unload_model?server=${nlu_deploy_url}&name=${runtime_name}`;
    postData(url, {}, dispatch, setRuntimeModelInfo, { on_error: updateRuntimeModelInfo });
  };

  const handleChange = event => {
    let v;
    setEditModelParams(v={ ...editModelParams, [event.target.id]: event.target.value });
  };

  const handleCheckboxChange = id => event => {
    const is_checked = event.target.checked;
    const other = id == 'is_external'
      ? {
        nlu_train_url: getDefaultValue('NLU_NB', 'deploy_url', configDefaults),
        ext_type: ext_type_init(is_checked),
        type: type_init(is_checked),
      } : {
        // nlu_train_url: getDefaultValue('NLU_NB', 'train_url', configDefaults), <-- FIXME: wrong train_url == deploy_url
        //                                                                            when changing to external and back
      };
    setEditModelParams({ ...editModelParams, [id]: is_checked, ...other });
  };

  const handleChangeTrainSet = (event, value, no_trainset) => {
    setEditModelParams({ ...editModelParams, trainSet: value.map(v => v._id), no_trainset })
  };

  const handleChangeConfig = (event, value) => {
    setEditModelParams({ ...editModelParams, model_config: value?._id || null })
  };

  const handleChangeNluType = (event, value) => {
    const type = value?.value;
    const nlu_train_url = editModelParams.nlu_train_url || getDefaultValue(type, 'train_url', configDefaults);
    const nlu_deploy_url = editModelParams.nlu_deploy_url || getDefaultValue(type, 'deploy_url', configDefaults);

    setEditModelParams({ ...editModelParams, type, nlu_train_url, nlu_deploy_url, model_config: null });
  };

  const handleChangeFilters = (event, value) => {
      setEditModelParams({
        ...editModelParams, 
        filters: (value || []).map(({ _id }, index) => ({
          filter: _id,
          index,
        })),
      })
  };

  const handleTextFieldFocus = event => setSavedValue(event.target.value);
  const handleTextFieldBlur = action => event => {
    const value = event.target.value;
    if (value != savedValue) {
      setSavedValue(value);
      if (value)
        action(value);
    }
  };

  const handleRuntimeNameChange = handleTextFieldBlur(updateRuntimeModelInfo);

  const closeEditConfig = () => { toggleEditConfig() };
  const doCancel = () => { onClose(BTN.CANCEL) };

  const doGetSchema = () => {
    return postData(`/api/model/get_data_schema`, {
      project: projectId,
      filters: editModelParams.filters,
    }, dispatch, data => {
      setEditModelParams({ ...editModelParams, nluConfigText: YAML.stringify(data) || null });
    });
  };

  const doSave = async () => {
    const save = {
      method: _id && !isClone? putData : postData,
      uri: _id ? `/api/model${isClone ? '/copy' : ''}/${_id}` : `/api/model`,
    };
    const model = { ...editModelParams, trainSet: editModelParams.trainSet.filter(id => id != NO_TRAINSET_ID) };

    await save.method(
      save.uri,
      { model },
      dispatch,
      data => {
        dispatch(
          setMessageState({
            snackBarMessages: t("common.save_success"),
            snackBarVariant: MESSAGE_STATUS.SUCCESS,
            snackBarState: true,
          }),
        );
        // Success message only in one place (if needed will be deleted)

        onClose(BTN.SAVE);
      }
    );
  };

  const getModelConfig = id => setSelectedConfig(modelConfigs.find(c => c._id == id));

  const getConfigDefaults = () =>
    getData(`/api/project/named_configs?project=${projectId}`, dispatch, setConfigDefaults);

  const getDefaultValue = (type, name, defaults) =>
    type ? defaults?.[NLUTypes[type]]?.[name] : '';

  const handlePreviewConfig = () => {
    setIsPreviewConfigOpen(!isPreviewConfigOpen);
  };

  const checkSample = () => {
    const trunc_floats = data => {
      const trunc = i => { const c = Number(i?.confidence); if (!isNaN(c)) i.confidence = c.toFixed(4) };
      trunc(data.intent);
      (data.intent_ranking || []).forEach(trunc);
      return data;
    };
    setCheckSampleInProgress(true);
    postData('/api/model/check_sample', {
      _id,
      model: editModelParams,
      text: checkSampleText,
    },
    dispatch,
    data => {
      setCheckSampleResult(JSON.stringify(trunc_floats(data), null, 4));
    }, {
      on_error: e => { throw e },
    }).catch(e => {
      setCheckSampleResult(t('common.error') +': '+ e.message +'!');
    }).finally(() => 
      setCheckSampleInProgress(false)
    );
  };

  const onPressEnter = action => event => {
    if (event.key == 'Enter')
      action(event);
  };

  const FieldSection = ({ id, legend, ...props }) => {
    const [isEmpty, setEmpty] = useState(true);

    useEffect(() => { setEmpty(!document.getElementById(id).innerText.trim()) });

    const copyToClipboard = id => async () => {
      let text = document.getElementById(id).innerText;
      try {
        await navigator.clipboard.writeText(text);
        showInfo(dispatch, t)('Content copied to clipboard');
      } catch (err) {
        const m = 'Failed to copy content to clipboard!';
        showError(dispatch, t)(m);
        console.error(m.slice(0,-1) +': ', err);
      }
    };

    return (
      <fieldset className={classes._fieldset} style={{width: "100%"}}>
        <legend className={classes.legend}>{legend}</legend>
        <div className={classes.inlineFloatRightIcon}>
          <IconButton
            title={t("common.copy_to_clipboard")}
            Icon={FileCopyOutlinedIcon}
            disabled={isEmpty}
            onClick={copyToClipboard(id)}
            fontSize='small'
          />
        </div>
        {props.children}
      </fieldset>
    )
  };

  const checkSampleContent = <>
    <div style={{margin: 10}}>
    <Grid container spacing={3}>
      {!is_ext_asr && <div className={classes.checkSampleFirstRow}>
        <TextField
          id="check_sample"
          size="small"
          label={t("train.check_sample_text")}
          variant="outlined"
          fullWidth
          value={checkSampleText}
          onChange={event => setCheckSampleText(event.target.value)}
          autoFocus
        />
        <Button
          disabled={!checkSampleText.trim() || checkSampleInProgress}
          variant="outlined"
          style={{ height: "40px", marginLeft: 10 }}
          onClick={checkSample}
        >
          {t("train.run_check_sample")}
        </Button>
      </div>}
      <FieldSection id="checkSampleCurl" legend={t('train.check_sample_curl')}>
        <div id="checkSampleCurl" style={{overflowWrap: 'anywhere'}}>
          {is_ext_nb ? `curl -s ${editModelParams.nlu_train_url}/predictions/${editModelParams.remote_name} -H 'Content-Type: application/json' -d '{"inputs": {"text": "${checkSampleText}"} }'`
            : is_ext_asr ? `curl -k --data-binary @audio.wav -H 'Transfer-Encoding: chunked' -H 'Content-Type: audio/x-pcm; rate=8000' -H 'Host: ${editModelParams.remote_name}' ${editModelParams.nlu_train_url}`
            : is_nb ? `curl -s ${editModelParams.nlu_deploy_url}/predictions/${editModelParams.runtime_name} -H 'Content-Type: application/json' -d '{"inputs": {"text": "${checkSampleText}"} }'`
            : '???'}
        </div>
      </FieldSection>
      {!is_ext_asr && <FieldSection id="checkSampleResult" legend={t('train.check_sample_result')}>
        <pre id="checkSampleResult" style={{ whiteSpace: 'pre-wrap', marginTop: 0, overflowWrap: 'anywhere' }}>
          {checkSampleInProgress ? (checkSampleResult || '').replace(/[^\n]/g,'') : checkSampleResult}
        </pre>
      </FieldSection>}
    </Grid>
    </div>
  </>;

  const NO_TRAINSET_ID = '__no_trainset__';
  const NO_TRAINSET_OPTION = isGAI(project) ? [{ name: t('train.no_trainset'), _id: NO_TRAINSET_ID }] : [];
  const trainSet = editModelParams.no_trainset
    ? NO_TRAINSET_OPTION
    : ((projectDataSets || []).map(({ name, _id }) => ({ name, _id}))
       .concat((editModelParams.trainSet.length ? [] : NO_TRAINSET_OPTION)));
  const _handleChangeTrainSet = (event, value) =>
    handleChangeTrainSet(event, value, value[0]?._id == NO_TRAINSET_ID);

  const internal = () => (
    <>
      <fieldset className={classes.fieldset}>
        <legend className={classes.legend}>{t('train.data')}</legend>
        <Grid container spacing={3}>
          <Grid item sm={12}>
            <MultiSelect
              id="trainSet"
              fullWidth
              required
              options={trainSet}
              onChange={_handleChangeTrainSet}
              getOptionLabel={(option) => option?.name}
              value={editModelParams.no_trainset ? NO_TRAINSET_OPTION
                : editModelParams.trainSet.map(id => trainSet.find(ds => ds._id == id))}
              label={t("train.train_set")}
            />
          </Grid>
          <Grid item sm={12}>
            <Autocomplete
              id="filters"
              size="small"
              multiple
              fullWidth
              required
              filterSelectedOptions
              options={modelFilters || []}
              disableCloseOnSelect
              onChange={handleChangeFilters}
              getOptionLabel={(option) => option?.name}
              value={(editModelParams.filters || []).map(({ filter }) =>
                  (modelFilters || []).find((mf) => mf._id === filter),
              )}
              renderOption={(option, { selected }) => (
                <React.Fragment>{option?.name}</React.Fragment>
              )}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label={t("train.filters")}/>
              )}
            />
            <div style={{ marginTop: "24px" }}>
              {editModelParams.type == 'NLU_RASA' ?
                <FormControlLabel
                  style={{color: 'initial'}}
                  control={<Checkbox/>}
                  label={t("train.include_data_schema")}
                  checked={editModelParams.include_data_schema === undefined  // for old models
                      || editModelParams.include_data_schema}
                  onChange={handleCheckboxChange('include_data_schema')}
                /> : null}
              <Button
                id="btnEdit"
                variant="outlined"
                onClick={() => {
                  doGetSchema()
                    .then(() => toggleEditConfig());
                }}
              >
                {t("train.preview_data_schema")}
              </Button>
            </div>
          </Grid>
        </Grid>
      </fieldset>
      <fieldset className={classes.fieldset}>
        <legend className={classes.legend}>{t('common.train_n')}</legend>
        <Autocomplete
          id="type"
          size="small"
          fullWidth
          required
          className={classes.dialogField}
          options={internalModelTypeACOpts}
          onChange={handleChangeNluType}
          getOptionLabel={(option) => option.name || ""}
          value={getACValue(internalModelTypeACOpts, NLU_TYPE_AC_OPTS.find(item => item.value === editModelParams.type) || null)}
          getOptionSelected={(option, value) => option.value === value.value || true}
          renderInput={(params) => (
            <TextField {...params} variant="outlined" label={t("train.nlu_type")}/>
          )}
          disabled={Boolean(_id) && !isClone || internalModelTypeACOpts.length == 1}
        />
        {editModelParams.type == 'ASR_E2E' &&
        <FormGroup style={{ marginLeft: 8 /* as in dialogField */ }}>
          <FormControlLabel
            // className={classes.dialogField}
            control={<Checkbox/>}
            label={t("train.check_dupes")}
            checked={editModelParams.check_dupes}
            onChange={event => {
              setEditModelParams({ ...editModelParams, check_dupes: event.target.checked });
            }}
          />
        </FormGroup>}
        {editModelParams.type && modelConfigs && <div style={{ display: "flex" }}>
          <Autocomplete
            style={{flexGrow: 1}}
            options={modelConfigs.filter(c => c.type == editModelParams.type) || []}
            groupBy={option => option.scope}
            id="config"
            required
            size="small"
            label={"Config"}
            onChange={handleChangeConfig}
            variant="outlined"
            getOptionLabel={(option) => option.name || ""}
            value={modelConfigs.find(c => c._id == editModelParams.model_config) || null}
            getOptionSelected={(option, value) => option._id === value._id || true}
            renderInput={(params) => (
              <TextField
                className={classes.dialogField}
                required
                {...params}
                variant="outlined"
                label={t("train.config")}
              />
            )}
          />
          <Button
            disabled={!editModelParams.model_config}
            variant="outlined"
            className={classes.dialogField}
            style={{ width: 114 /* manually adjusted in order to the PREVIEW button be the same size as the INFO button in BackendSettings below */ }}
            onClick={() => {
              getModelConfig(editModelParams.model_config);
              handlePreviewConfig();
            }}>
            {t("train.preview_config")}
          </Button>
        </div>}
        {editModelParams.type && <BackendSettings
          fields={['nlu_train_url']}
          settings={editModelParams}
          setSettings={(name, opts = {}) => data => {
            const value_obj = opts.is_direct ? data : { [name]: data.target.value };
            setEditModelParams({ ...editModelParams, ...value_obj });
          }}
          fieldDescr={{ nlu_train_url: {
            type: 'nb_server',
            props: { id: 'nlu_train_url', required: true, label: t("train.train_test_url") },
            classes: classes.dialogFieldMargin,
            info: true,
            classes_info: classes.dialogField,
          }}}
        />}
      </fieldset>
      {['NLU_RASA', 'NLU_NB'].includes(editModelParams.type) && (
        <fieldset className={classes.fieldset}>
          <legend className={classes.legend}>{t('common.runtime')}</legend>
          <BackendSettings
            fields={['nlu_deploy_url']}
            settings={editModelParams}
            setSettings={(name, opts = {}) => data => {
              const value_obj = opts.is_direct ? data : { [name]: data.target.value };
              setEditModelParams({ ...editModelParams, ...value_obj });
            }}
            fieldDescr={{ nlu_deploy_url: {
              type: 'nb_runtime',
              props: { id: 'nlu_deploy_url', label: t("train.runtime_url") },
              classes: classes.dialogFieldMargin,
              info: true,
              classes_info: classes.dialogField,
            }}}
          />
          <TextField
            id="runtime_name"
            label={t("train.runtime_name")}
            className={classes.dialogField}
            size="small"
            variant="outlined"
            fullWidth
            value={editModelParams.runtime_name}
            onChange={handleChange}
            onFocus={handleTextFieldFocus}
            onBlur={handleRuntimeNameChange}
            onKeyDown={onPressEnter(handleRuntimeNameChange)}
          />
          <div className={classes.runtimeModelStatus}>
            <div className={classes.legend +' '+ classes.dialogField} style={{overflow: 'hidden', width: '40%', padding: 0}}>
              {runtimeModelInfo.missing ? t('train.not_loaded') : t('train.loaded_as', runtimeModelInfo)}
            </div>
            <div className={classes.runtimeModelButtons}>
            <Button
              disabled={not_available}
              variant="outlined"
              className={classes.dialogField}
              style={{ height: "40px", marginTop: "8px" }}
              onClick={() => setShowCheckSample(true)}
            >
              {t("train.show_check_sample")}
            </Button>
            <Button
              disabled={not_available}
              variant="outlined"
              className={classes.dialogField}
              style={{ height: "40px", marginTop: "8px" }}
              onClick={handleUnloadModel}>
              {t("train.unload_runtime_model")}
            </Button>
          </div>
          </div>
        </fieldset>
      )}
    </>
  );

  const handleChangeExtType = (event, value) => {
    const ext_type = value?.value || null;
    const type = toType(ext_type);

    setEditModelParams({ ...editModelParams, type, ext_type })
  };

  const onExtModelChange = name => (event, value) => {
    setEditModelParams({ ...editModelParams, [name]: value });
  };

  const external = () => {
    return (<>
      <fieldset className={classes.fieldset}>
        <legend className={classes.legend}>{t('train.ext_model')}</legend>
        <Grid container spacing={2}>
          <Grid item sm={12}>
            <Autocomplete
              id="ext_type"
              size="small"
              fullWidth
              required
              options={externalModelTypeACOpts}
              onChange={handleChangeExtType}
              getOptionLabel={option => option.name || ""}
              value={getACValue(externalModelTypeACOpts, EXT_TYPE_OPTS.find(item => item.value == editModelParams.ext_type) || null)}
              getOptionSelected={(option, value) => option.value == value.value || true}
              renderInput={params => (
                <TextField {...params} variant="outlined" label={t("train.ext_type")}/>
              )}
              disabled={externalModelTypeACOpts.length == 1}
            />
          </Grid>
          {editModelParams.ext_type && (<>
            <Grid item sm={12}>
              <BackendSettings
                fields={['nlu_train_url']}
                settings={editModelParams}
                setSettings={(name, opts = {}) => data => {
                  const value_obj = opts.is_direct ? data : { [name]: data.target.value };
                  setEditModelParams({ ...editModelParams, ...value_obj });
                }}
                fieldDescr={{ nlu_train_url: {
                  type: 'nb_runtime',
                  props: { id: 'nlu_train_url', label: t("train.ext_url") },
                  classes: is_ext_asr ? '' : classes.dialogInnerField,
                  onChange: updateExtModelList,
                  info: !is_ext_asr,
                }}}
              />
            </Grid>
            <Grid item sm={12}>
              {!is_ext_nb &&
                <TextField
                  id="remote_name"
                  required
                  size="small"
                  label={t("train.ext_name")}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  value={editModelParams.remote_name}
                  inputProps={{ readOnly: !is_ext_asr }}
                />}
              {is_ext_nb &&
                <Autocomplete
                  required
                  id="remote_name"
                  options={extModelList.models || []}
                  onChange={onExtModelChange("remote_name")}
                  value={editModelParams.remote_name || null}
                  renderInput={params =>
                    <TextField {...params} variant="outlined" label={t("train.ext_name")}/>
                  }
                  fullWidth
                  size="small"
                />}
            </Grid>
          </>)}
        </Grid>
      </fieldset>
    </>);
  };

  const EXT_MUST_HAVE_FIELDS = ['name', 'ext_type', 'nlu_train_url', 'remote_name'];

  return (
      <Dialog
        maxWidth="sm"
        fullWidth={true}
        open={true}
        onClose={notBackdropClicked(doCancel)}
        scroll="paper"
        aria-labelledby="edit-model-dialog-title"
      >
        <EditConfig
          isOpen={isShowingEditConfig}
          onClose={closeEditConfig}
          nluConfigText={editModelParams.nluConfigText}
        />
        {showCheckSample && <ConfirmDialog
          title={t("train.check_sample", { name: editModelParams.name || '<unnamed>' })}
          content={checkSampleContent}
          showBtnNameAgree={false}
          btnNameDisagree={t("common.close")}
          closeModal={() => setShowCheckSample(false)}
          maxWidth='md'
        />}

        <CloseBar onClose={doCancel} title={_id ? isClone ? t("train.clone") : t("train.edit") : t("train.new")}/>
        <DialogContent dividers={true}>
          {isPreviewConfigOpen &&
          <Dialog
            fullWidth={true}
            open={isPreviewConfigOpen}
            onClose={handlePreviewConfig}
            scroll="paper"
            maxWidth="sm"
          >
            <CloseBar onClose={handlePreviewConfig} title={selectedConfig?.name}/>
            <CodeMirror
              value={selectedConfig?.data}
              options={{
                mode: "yaml",
                lineNumbers: true,
              }}
              readOnly
            />
          </Dialog>
          }
          <TextField
            id="name"
            required
            size="small"
            className={classes.dialogField}
            label={t("train.model_name")}
            onChange={filterName(handleChange)}
            variant="outlined"
            fullWidth
            value={editModelParams.name || ""}
          />
          <TextField
            id="description"
            size="small"
            className={classes.dialogField}
            label={t("common.description")}
            onChange={handleChange}
            variant="outlined"
            fullWidth
            defaultValue={editModelParams.description || ""}
          />
          {_id ? null :
          <FormControlLabel
            style={{marginLeft: 0}}
            control={<Checkbox/>}
            label={t("train.is_external")}
            checked={editModelParams.is_external || false}
            onChange={handleCheckboxChange('is_external')}
          />}
          {editModelParams.is_external ? external() : internal()}
        </DialogContent>
        <DialogActions>
          {(is_ext_nb || getRunConfigOption('showASRCheckSampleCmd') && is_ext_asr) && (
            <Button
              disabled={['nlu_train_url', 'remote_name'].some(k => !editModelParams[k])}
              onClick={() => setShowCheckSample(true)}
            >
              {t("train.show_check_sample")}
            </Button>
          )}
          <Button onClick={doCancel}>{t("common.cancel")}</Button>
          <Button
            disabled={editModelParams.is_external
              ? EXT_MUST_HAVE_FIELDS.some(k => !editModelParams[k])
              : (['model_config', 'name', 'trainSet', 'type', 'nlu_train_url'].some(k => !editModelParams[k])
                || !editModelParams.trainSet?.filter(id => id != NO_TRAINSET_ID).length && !editModelParams.no_trainset)}
            onClick={doSave} color="primary" autoFocus
          >
            {t(isClone ? "common.clone" : "common.save")}
          </Button>
        </DialogActions>
      </Dialog>
  );
}

function ExpandableString(props) {
  const { maxlen, data: _d = '' } = props;
  const data = ucfirst(_d);
  const [full, setFull] = useState(false);
  useEffect(() => setFull(false), [data]);
  const fits = data.length <= maxlen;
  return (
    <span onClick={() => setFull(!full)} style={fits ? {} : {cursor: 'pointer'}}>
      {full || !maxlen ? data : data.slice(0,maxlen)+(fits ? '' : ' ...')}
    </span>
  );
}

const mapStateToProps = (state) => ({
  projectId: state.settings.projectId,
  modelFilters: state.modelFilters.filters,
  modelConfigs: state.settings.modelConfigList,
});

export default connect(mapStateToProps)(EditDialog);
