import React, { useEffect } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router";

import { withStyles } from "@material-ui/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import GetAppIcon from "@material-ui/icons/GetApp";
import BlockIcon from "@material-ui/icons/Block";
import DeviceHubIcon from "@material-ui/icons/DeviceHub";
import TableCell from "@material-ui/core/TableCell";
/* import Tooltip from "@material-ui/core/Tooltip"; */
import Radio from "@material-ui/core/Radio";

import { SimpleMenu } from "../../components/material-ui/Menu";

import {
  MESSAGE_STATUS,
  DIALOG_USER_STATE,
  FILE_FORMATS,
}                                     from "../../../core/constants";
import {
  download,
  showDate,
  decodeMessage,
  showError,
  filterKeys,
  withTranslation,
  GlobalState,
  getProject,
  isGAI,
}                                     from "../../../core/utils";
import {
  getData,
  deleteData,
  putData,
}                                     from "../../../core/fetchService";
import {
  getSelectedDataSetId,
  fetchProjectDataSets,
  fetchDataSets,
  updateDataset,
  changeDataset,
}                                     from "../../../features/settings";
import { setMessageState }            from "../../../features/messageInfo";

import IconButton                     from "../../components/material-ui/IconButton";

import EnhancedTable, { viewLink }    from "../../components/projectTable";
import ConfirmDialog                  from "../../components/confirmDialog";

import {
  viewStatus,
  linkStyle,
  Runnable,
  Runnables,
}                                     from "../../components/taskStatus";

import Scrollable                     from '../../components/helpers/scrollable';

import EditDialog, {
  UploadVariant,
  defaultDcImportConfig,
}                                     from './EditDatasetDialog'

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    display: "flex",
    flexWrap: "wrap",
    height: '100%',
    overflow: 'auto',
  },
  button: {
    marginRight: 10,
  },
});

function viewDatasetStatus(row, cell) {
  const s_obj = row.execution;
  return viewStatus(row, cell, {
    s_obj,
    percent: s_obj?.progress?.percent,
    in_progress: {      /* Note: statuses' names (below) must match to runnables' params */
      vectorize:        'Vectorizing',
      upload:           'Loading',
      import_from_dc:   'Loading',
    }[s_obj?.task_name],
  });
}

function viewStat(row, cell) {
  return row[cell._id] == 0 ? '' : row[cell._id];
}

function viewSize(row, cell) {
  return viewStat(row, cell);
  /*
  return row[cell._id] == 0 ? '-'
    : <Tooltip title={`manual: ${row.manual}, auto: ${row.auto}`}><div>{row[cell._id]}</div></Tooltip>;
    */
}

class Datasets extends React.Component{
  constructor(props) {
    super(props);
    const { projects, projectId } = props;

    this.state = this.getClearState_1();
    this.scrollable = new Scrollable(this);

    /* In fact, the actions (upload/import_from_dc and vectorize), corresponding to these
     * two runnables, are mutually exclusive. Still, it's simpler to use two similar runnables,
     * than to complicate the logic inside Runnable to support multiple statusName values.
     */
    this.runnables = new Runnables({
      loadable: new Runnable({
        taskNames: ['upload', 'import_from_dc'],
        getTaskName: ds => ds?.execution?.task_name,
        getStatus: ds => ds?.execution?.status,
        statusName: 'Loading',
        updateStatus: this.checkStatus,
        addActiveStatuses: ['Fetching', 'Parsing'],
      }),
      vectorizable: new Runnable({
        taskNames: ['vectorize'],
        getTaskName: ds => ds?.execution?.task_name,
        getStatus: ds => ds?.execution?.status,
        statusName: 'Vectorizing',
        updateStatus: this.checkStatus,
        addActiveStatuses: ['Preparing dataset'],
      }),
    });

    this.initMethodsForRunnables();
    this.globalState = new GlobalState(this.fetchProjectDatasets);
  }

  isGenerative = () => {
    const { projects, projectId } = this.props;
    return isGAI(getProject(projects, projectId));
  };

  getClearState = () => ({
    editDialogOpen: false,
    dataSetName: null,
    description: "",
    similarityConfig: undefined,
    formData: null,
    unique: false,
    lfdcFormContent: {
      intent_slot: "dummy",
      ...defaultDcImportConfig,
    },
    composerConfig: undefined,
    uploadProperties: {
      update_intents: false,
      check_audio: false,
    },
    tabValue: 0,
    header: false,
    role: "user",
    annStatus: "auto",
    radioValueFileType: this.isGenerative() ? "csv" : "json",
    intentSlotChecked: true,
    aniChecked: false,
    channelChecked: false,
    copyProps: {
      keep_intents: true,
      keep_entities: true,
    },
    copySlice: {
      start: '',
      end: '',
    },
  });

  getClearState_1 = () => ({
    ...this.getClearState(),
    showModal: false,
    dialogTitle: undefined,
    uploadVariant: UploadVariant.File,
    modalContent: "",
    rowId: null,
    fileName: null,
    csvSeparator: "\\t",
    csvExtIdField: "1",
    csvInputField: "2",
    csvOutputField: "3",
    csvUtterance: "1",
    csvIntentField: "2",
    csvConfidence: this.isGenerative ? "" : "3",
    csvAudio: "4",
    showBtnNameAgree: true,
    showConfirmStatus: false,
  });

  getClearState_2 = () => ({ // TODO: same as 1
    ...this.getClearState(),
    absoluteDateChecked: false,
    relativeDateChecked: false,
  });

  initMethodsForRunnables = () => {
    this.isDatasetBeingProcessed = this.runnables.isObjActive;

    this.isStatusVectorizing = this.runnables.vectorizable.isRunning;
    this.isStatusLoading =     this.runnables.loadable.isRunning;

    this.isStatusRunning = this.runnables.isRunning;
    this.isActive =        this.runnables.isActive;
  };

  componentDidMount() {
    this.scrollable.componentDidMount();
    this.globalState.componentDidMount();
  }

  componentDidUpdate(prevProps) {
    this.runnables.componentDidUpdate(this.props.projectDatasets);
    this.globalState.componentDidUpdate(prevProps, this.props);
  }

  componentWillUnmount()  { this.runnables.componentWillUnmount() }

  checkStatus = (datasets, adjust) => this.props.dispatch(fetchDataSets({ datasets, adjust }));

  adjustStatusTo = (status, ds, task_name) => ({ ...ds, execution: { ...ds.execution, task_name, status } });

  setStatusWaiting = (dataset, task_name) => {
    const { dispatch } = this.props;
    // this is to disable VECTORIZE/STOP icon in case a request is delayed/hanged up
    // with auto-update ON ('Waiting...' is in Runnable.isStatusActive)
    dispatch(updateDataset(this.adjustStatusTo('Waiting...', dataset, task_name)));
  }

  /*
  setStatusSaving = dataset => {
    const { dispatch } = this.props;
    // this is to disable VECTORIZE/STOP icon in case a request is delayed/hanged up
    // with auto-update OFF ('Saving...' is NOT in Runnable.isStatusActive)
    dispatch(updateDataset(this.adjustStatusTo('Saving...', dataset)));
  }
  */

  vectorize = (id, stop=false) => {
    const { t, dispatch, projectDatasets } = this.props;
    const dataset = projectDatasets.find(ds => ds._id == id);
    this.setStatusWaiting(dataset, 'vectorize');
    const [action, action_label] = stop
      ? (this.isStatusVectorizing(projectDatasets, id) ? ['stop_vectorize', 'datasets.vecn_stopped']
         : this.isStatusLoading(projectDatasets, id)   ? ['stop_upload', 'datasets.load_stopped']
         :                                               ['UNKNOWN', '_unk'])
      :                                                  ['vectorize', 'datasets.vectorizing'];
    const msg_params = stop ? {} : { t, message_prefix: 'settings' };

    if (action == 'UNKNOWN')
      return showError(dispatch, t)('datasets.no_process', dataset);

    getData(`/api/dataset/${id}/${action}`, dispatch, data => {
      dispatch(setMessageState({
        snackBarMessages: t(action_label),
        snackBarVariant: stop ? MESSAGE_STATUS.INFO : MESSAGE_STATUS.SUCCESS,
        snackBarState: true,
      }));
      if (!stop)
        this.checkStatus([dataset]);
    }, { ...msg_params, on_error: () => this.checkStatus([dataset]) }).finally(() => {
      if (stop)
        this.checkStatus([dataset]);
    });
  };

  handleVectorizeStopClick = (id, stop) => {
    const { t, projectDatasets: datasets } = this.props;
    const dataset = datasets.find(d => d._id == id);
    const name = dataset?.name;
    if (!stop)
      return this.setState({
        showModal: 'vectorize',
        rowId: id,
        dialogTitle: t('datasets.vecn_title', { name }),
        modalContent: t('datasets.vecn_question'),
      });
    const [stop_action_label, stop_question_label]
      = this.isStatusVectorizing(datasets, id) ? ['datasets.stop_vecn', 'datasets.stop_vecn_question']
      : this.isStatusLoading(datasets, id)   ? ['datasets.stop_upload', 'datasets.stop_upload_question']
      : ['???', '???'];
    this.setState({
      showModal: 'stop',
      rowId: id,
      dialogTitle: t(stop_action_label, { name: datasets.find(d => d._id == id)?.name }),
      modalContent: t(stop_question_label),
    });
  };

  fetchProjectDatasets = options =>
    this.props.dispatch(fetchProjectDataSets(this.props.projectId, options));

  handleClickNewRow = (id) => {
    const { projectDatasets, projects, projectId } = this.props;
    const { composerConfig: projectComposerConfig } = projects.find(p => p._id == projectId);

    const dataset = projectDatasets?.find(ds => ds._id == id);
    const {
      name: dataSetName = null,
      description = "",
      importConfig = null,
      unique = true,
      similarityConfig,
      composerConfig,
    } = dataset || {};

    const hasImportConfig = Boolean(projectDatasets.find(ds => ds._id == id)?.importConfig);
    this.setState({
      tabValue: Number(hasImportConfig),
      uploadVariant: hasImportConfig ? UploadVariant.Composer : UploadVariant.File,
      lfdcFormContent: { ...(importConfig || this.state.lfdcFormContent) },
      composerConfig: { ...(composerConfig || this.state.composerConfig || projectComposerConfig) },
      intentSlotChecked: Boolean(importConfig?.intent_slot),
      aniChecked: Boolean(importConfig?.ani),
      channelChecked: Boolean(importConfig?.channel),
      editDialogOpen: true,
      fileName: null,
      rowId: id,
      dataSetName,
      description,
      unique,
      similarityConfig,
    });
  };

  handleDownloadFile = (id, name, fileFormat) =>
    download(`/api/dataset/${id}/download?format=${fileFormat}`, this.props.dispatch);

  handleDeleteRow = (id, name) => {
    const { t } = this.props;
    this.setState({
      showModal: 'delete',
      rowId: id,
      dialogTitle: t("datasets.confirm_title"),
      modalContent: `Delete data set: ${name}?`,
    });
  };

  handleCloseDeleteModal = modalState => {
    const { selectedDataSetId, dispatch } = this.props;
    const { rowId, showModal: action } = this.state;
    this.setState({ showModal: false });
    if (rowId && modalState === DIALOG_USER_STATE.AGREE)
      if (['vectorize', 'stop'].includes(action))
        this.vectorize(rowId, action == 'stop');
      else if (action == 'delete')
        deleteData(`/api/dataset/${rowId}`, this.props.dispatch, data => {
          this.fetchProjectDatasets({ stat: false }).then(() => {
            if (rowId == selectedDataSetId)
              dispatch(changeDataset());
          });
        });
  };

  handleRowItemChanged = obj => {
    const { t, dispatch } = this.props;
    putData(`/api/dataset/${obj._id}`, {
      dataset: {
        [obj.rowName]: obj[obj.rowName],
        project: this.props.projectId,
      },
    }, dispatch, data => {
      this.checkStatus([{ _id: obj._id }]);
    }, {
      error_prefix: t('datasets.save_error'),
    });
  };

  handleExportMenuClose = (id, name) => fmt => {
    if (fmt)
      this.handleDownloadFile(id, name, fmt);
  };

  render() {
    const { classes, t, projects, projectId, projectDatasets, selectedDataSetId } = this.props;
    const { nluConfig: projectNLUConfig, similarityConfig: projectSimilarityConfig }
      = projects.find(p => p._id == projectId);

    const {
      showModal,
      dialogTitle,
      modalContent,
      showConfirmStatus,
      editDialogOpen,
      radioValueFileType,
      showBtnNameAgree,
      rowId,
      dataSetName,
      uploadProperties,
      copyProps,
      similarityConfig,
    } = this.state;

    const getModalStatusContent = () => {
      const row = projectDatasets.find(d => d._id == rowId);
      if (!row) return null;
      const getErrorMessage = e => decodeMessage(e, this.props.t, 'datasets') || JSON.stringify(e);
      // TODO: common status dialog (see also ModelStatusDialog, modalContentStatus (tests and autoAnnotation))
      const show_error = s => s?.error ? (
        <tr style={{ color: "red" }}>
          <td>{t("common.error")}</td>
          <td>{getErrorMessage(s.error.error || s.error)}</td>
        </tr>
      ) : null;
      const show_progress = p => p ? (
        <>
          <tr><td>{t("progress.items")}</td><td>{p.current} {t("progress.items_of")} {p.total}</td></tr>
          <tr><td>{t("progress.percent")}</td><td>{p.percent}%</td></tr>
          <tr><td>{t("progress.time_elapsed")}, {t('common.seconds_a')}</td><td>{p.time_total}</td></tr>
          <tr><td>{t("progress.time_left")}, {t('common.seconds_a')}</td><td>{p.est_time_left}</td></tr>
        </>
      ) : null;
      const s = row.execution;
      const p = s?.progress;
      return <table style={{width: '100%'}}><tbody>
        {show_error(s)}
        {['upload', 'import_from_dc'].includes(s.task_name) ? (
          <>
            <tr>
              <td>{t("datasets.load_start")}</td>
              <td><span style={{ textDecoration: "underline" }}>{showDate(s?.started)}</span></td>
            </tr>
            {s.status == 'Loading...' ? show_progress(s?.progress) : null}
            <tr>
              <td>{t(s.status != "Aborted" ? "datasets.load_end" : "datasets.load_abort")}</td>
              <td><span style={{ textDecoration: "underline" }}>
                {!this.isDatasetBeingProcessed(row)? showDate(s.finished) : ''}
              </span></td>
            </tr>
          </>
        ) : s.task_name == 'vectorize' ? (
          <>
            <tr>
              <td>{t("datasets.vecn_start")}</td>
              <td><span style={{ textDecoration: "underline" }}>{showDate(s.started)}</span></td>
            </tr>
            {s.status == 'Vectorizing...' && p && p.queued === undefined ? show_progress(p) : null}
            <tr>
              <td>{t(s.status != "Aborted" ? "datasets.vecn_end" : "datasets.vecn_abort")}</td>
              <td><span style={{ textDecoration: "underline" }}>
                {!this.isDatasetBeingProcessed(row) && p?.queued === undefined ? showDate(s.finished) : ''}
              </span></td>
            </tr>
          </>
        ) : (
          <tr><td>error: unknown_task!!</td></tr>
        )}
      </tbody></table>;
    }

    const headCells = [
      {
        _id: "name",
        label: t("common.name"),
        width: "auto",
        textSearch: true
      },
      {
        _id: "description",
        label: t("common.description"),
        width: "20%",
        textSearch: true,
        optional: true,
      },
      {
        _id: "size",
        label: t("datasets.num_rows_total"),
      },
      {
        _id: "manual",
        label: t("datasets.num_rows_manual"),
      },
      {
        _id: "auto",
        label: t("datasets.num_rows_auto"),
      },
      {
        _id: "updatedAt",
        label: t("common.date"),
        width: "20%",
        align: "left",
        dateTime: true,
      },
      {
        _id: "datasetStatus",
        label: t("common.status"),
        width: "15%",
        link: row => () => {
          this.setState({ showConfirmStatus: true, rowId: row._id });
        },
        style: linkStyle(row => [row.execution, this.isDatasetBeingProcessed(row)],
                         { Loaded: 'green', Vectorized: 'green' }),
      },
    ];

    const state = rowId ? this.state : {
      ...this.state,
      similarityConfig: {
        server: similarityConfig?.server ?? projectNLUConfig?.train_url,
        ...projectSimilarityConfig,
        ...similarityConfig,
      }
    };
    
    return (
      <div className={classes.root}>
        {showModal && (
          <ConfirmDialog
            maxWidth='sm'
            title={dialogTitle}
            open={true}
            showBtnNameAgree={showBtnNameAgree}
            content={modalContent}
            closeModal={this.handleCloseDeleteModal}
          />
        )}
        {showConfirmStatus && (
          <ConfirmDialog
            open={true}
            content={getModalStatusContent()}
            showBtnNameAgree={false}
            title={t("common.info")}
            btnNameDisagree={t("common.close")}
            closeModal={() => this.setState({ showConfirmStatus: false })}
          />
        )}
        {editDialogOpen && (
          <EditDialog
            {...{
              rowId, selectedDataSetId, state, setState: this.setState.bind(this),
              ...filterKeys(this, 'checkStatus setStatusWaiting adjustStatusTo'),
              ...filterKeys(this, 'fetchProjectDatasets getClearState_2')
            }}
          />
        )}
        {projectDatasets && (
          <EnhancedTable
            id="datasets"
            useLocationSearch
            headCells={headCells}
            rows={projectDatasets.map(ds => ds.execution ? { ...ds, datasetStatus: ds.execution?.status } : ds)
                .map(ds => { if (!ds.stat) return ds; const { updatedAt, ...ds_stat } = ds.stat; return { ...ds, ...ds_stat } })}
            viewCell={{ datasetStatus: viewDatasetStatus, size: viewSize, manual: viewStat, auto: viewStat }}
            toolBarName={t("datasets.datasets")}
            newRowTitle={t("datasets.new")}
            handleClickNewRow={() => this.handleClickNewRow(null)}
            handleClickCheckBox={() => this.handleClickCheckBox}
            handleClickUpdateRow={this.fetchProjectDatasets}
            handleRowItemChanged={this.handleRowItemChanged}
            checkBoxTableCell={id => <TableCellRadioButton id={id}/>}
            customBtns={(name, id) => {
              const is_active = this.isActive(projectDatasets, id);
              const is_status_running = this.isStatusRunning(projectDatasets, id);
              const export_choices = Object.fromEntries(['JSON', 'CSV'].map(k => [FILE_FORMATS[k],k]));
              return (
                <div style={{width: "25%", display: "flex"}}>
                  {this.isGenerative() ? null :
                  <IconButton
                    title={t(is_active ? "common.stop" : "datasets.vectorize")}
                    style={is_active ? {} : {transform: 'rotate(180deg)'}}
                    variant="outlined"
                    className={classes.button}
                    disabled={is_active && !is_status_running}
                    onClick={() => this.handleVectorizeStopClick(id, is_active)}
                    Icon={is_active ? BlockIcon : DeviceHubIcon}
                  />}
                  <IconButton
                    className={classes.button}
                    title={t("common.edit")}
                    onClick={() => this.handleClickNewRow(id)}
                    Icon={EditIcon}
                  />
                  <SimpleMenu
                    Icon={GetAppIcon}
                    choices={export_choices}
                    title={t("common.export")}
                    handleClose={this.handleExportMenuClose(id, name)}
                  />
                  <IconButton
                    title={t("common.delete")}
                    onClick={() => this.handleDeleteRow(id, name)}
                    Icon={DeleteIcon}
                    style={{marginLeft: 10}}
                  />
                </div>
              )
            }}
          />
        )}
      </div>
    );
  }
}

function TableCellRadioButton({ id }) {
  const selectedDataSetId = useSelector(getSelectedDataSetId);
  const dispatch = useDispatch();

  return (
    <TableCell padding="checkbox">
      <Radio
        checked={id == selectedDataSetId}
        onChange={() => dispatch(changeDataset(id))}
      />
    </TableCell>
  );
}

const mapStateToProps = (state) => ({
  projects: state.settings.projects,
  projectId: state.settings.projectId,
  projectDatasets: state.settings.projectDatasets,
  selectedDataSetId: state.settings.selectedDataSetId,
});

export default withRouter(connect(mapStateToProps)(withStyles(styles)(withTranslation()(Datasets))),);
