import React, { Fragment, useEffect, useState, useCallback } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
  getProjectId,
  addToLookupDict,
  updateLookupDict,
  fetchEntityValuesByLookupId,
  getSelectedDataSetId,
  getLookupList,
  getSlotsList,
  fetchIntents,
  fetchSlots,
  fetchAddToLookup,
  fetchUpdateInLookup,
  fetchTestModelList,
  changeDataset,
} from "../../../features/settings";
import { setMessageState } from "../../../features/messageInfo";
import {
  deleteData, putData, getData, postData,
} from "../../../core/fetchService";
import {
  MESSAGE_STATUS,
  DIALOG_USER_STATE,
  COLORS,
} from "../../../core/constants";
import {
  delay, groupById, toFloat, isChanged, download, getRunConfigOption,
  useTranslation, showError, getEnding, isMaybeASR, isMaybeNLU, isGAI,
} from "../../../core/utils";
import EnhancedTable from "../../components/projectTable";
import ShareIcon from "@material-ui/icons/Share";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import Popper from "@material-ui/core/Popper";
import TableCell from "@material-ui/core/TableCell";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import ConfirmDialog from "../../components//confirmDialog";
import Tooltip from "@material-ui/core/Tooltip";
import TableRow from "@material-ui/core/TableRow";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Autocomplete, { AutocompleteFilterOptions } from "../../components/material-ui/Autocomplete";
import CoreAutocomplete from "@material-ui/lab/Autocomplete";
import PostAddIcon from "@material-ui/icons/PostAdd";
import EditIcon from "@material-ui/icons/Edit";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CompareArrowsIcon from "@material-ui/icons/CompareArrows";
import FilterListIcon from "@material-ui/icons/FilterList";
import {
  setExpandedList,
  expandAllRows,
  collapseAllRows,
} from "../../../features/annotationSettings";
import { SOME_SETTINGS } from "../../../core/constants";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import { ButtonGroup, Typography } from "@material-ui/core";
import Similarity from "./Similarity";
import ReportDialog, { testModelCompareName } from '../tests/ReportDialog';
import Link from "@material-ui/core/Link";
import AudioPlayer from 'material-ui-audio-player';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import CloseBar from "../../components//dialogCloseBar";
import { FormControl } from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import notBackdropClicked from "../../components/helpers/notBackdropClicked";
import Scrollable from '../../components/helpers/scrollable';

const useStyles = makeStyles((theme) => ({
  tableRow: {
    // backgroundColor: 'beige'
  },
  tableHeader: {
    padding: "10px",
  },
  tableBody: {},
  subcell: {
    backgroundColor: "#fefefe",
  },
  paper: {
    textAlign: "center",
    padding: 0,
  },
  margin: {},
  backdrop: {
    overflow: "auto",
    position: "absolute",
    zIndex: -1,
    transition: "transform 1s",
  },
  highlight: {
    whiteSpace: "pre-wrap",
    wordWrap: "break-word",
    color: "transparent",
    padding: 2,
  },
  highLightContainer: {
    display: "block",
    margin: "0 auto",
    transform: "translateZ(0)",
  },
  highlightTextArea: {
    width: "100%",
    margin: 0,
    borderRadius: 0,
    backgroundColor: "transparent",
    fontSize: "0.875rem",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontWeight: 400,
    lineHeight: 1.43,
    letterSpacing: "0.01071em",
    resize: "unset",
    border: "none",
    /* color: "transparent", */
    /* caretColor: "black", */
  },
  downloadSelect: {
    marginTop: 5,
    width: 25,
    '&:before': {
      border: 'none',
    },
    '&:hover:before': {
      border: 'none !important',
    },
  },
  underlinedInput: {
    '& .MuiInputBase-root:before': {
      borderBottom: 0,
    },
    '& .MuiInputBase-root .MuiAutocomplete-endAdornment': {
      display: 'none',
    },
    '& .MuiInputBase-root:hover .MuiAutocomplete-endAdornment': {
      display: 'initial',
    },
  },
}));

function annotationText(text, row) {
  return text && row.start >= 0 && row.end > 0
    ? text.substring(row.start, row.end).toLowerCase()
    : "";
}

function removeSplits(selectedTextObj) {
  let i = selectedTextObj.value.length;
  while (i--) {
    if (selectedTextObj.value[i] === " ") {
      selectedTextObj.value = selectedTextObj.value.substring(0, i);
      selectedTextObj.end--;
    } else break;
  }

  for (let i = 0; i < selectedTextObj.value.length; i++) {
    if (selectedTextObj.value[i] === " ") {
      selectedTextObj.value = selectedTextObj.value.substring(
        i + 1,
        selectedTextObj.value.length,
      );
      selectedTextObj.start++;
    } else break;
  }

  return selectedTextObj;
}

function findIntersections({ dispatch, t, rowSlots, selectedTextObj, text }) {
  if (!rowSlots)
    return false;
  for (const slot of rowSlots) {
    if (slot.start == undefined || slot.end == undefined)
      continue;
    const { start, end } = selectedTextObj;
    if (slot.start <= start && slot.end >= start
        || slot.start <= end && slot.end >= end
        || start <= slot.start && end >= slot.end
    ) {
      showError(dispatch, t)('annotation.text_has_intersections', {
        text: text.substring(start, end),
        slot: text.substring(slot.start, slot.end),
      });
      return true;
    }
  }
  return false;
}

/** This wrapper is to fix warning:
 *
 * "Material-UI: You are providing a disabled `button` child to the Tooltip component.
 * A disabled element does not fire events.
 * Tooltip needs to listen to the child element's events to display the title.
 * 
 * Add a simple wrapper element, such as a `span`."
 */
const CustomIconButton = React.forwardRef((props,ref) => {
  const { disableElevation, fullWidth, disableFocusRipple, disableRipple, ...iprops } = props;
  return (
    <span ref={ref}>
      <IconButton size="small" {...iprops}>
        {props.children}
      </IconButton>
    </span>
  );
});

// TODO: move to Autocomplete.js
const addInputValue = get_value_text => (options, params) => {
  const filtered = AutocompleteFilterOptions(options, params);
  if (!params.inputValue)
    return filtered;
  if (!options.find(o => String(o.value).trim() == String(params.inputValue).trim()))
    filtered.push({
      inputValue: params.inputValue,
      value: get_value_text(params.inputValue),
    });
  return filtered;
};

// TODO: move to Autocomplete.js
const getOptionLabelValue = option =>
  typeof option == "string" ? option : (option.inputValue || option.value);


/* Note: exactly same as in BE: routes/api/datasetrow.js
 */
const getListEntityValue = (entity, value) =>
  entity && value && entity.entityType == "list"
  && (entity.values.find(v => v.value == value)
      || entity.values.find(v => v.synonyms.includes(value)))
  || undefined;

const addEntityAnnotationData = (data, entity, synonym) => {
  const entity_value
    = entity.entityType == 'list' ? getListEntityValue(entity, synonym) : undefined;

  data.lookupId = entity._id;
  data.entityId = entity_value?._id;
  data.value = entity_value ? entity_value.value : synonym;
  return data;
};


export function AnnotationSubTable({ rowSlots, rowId, selectedTextObj, text, updateAnnotationRow }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const slotList = useSelector(getSlotsList);
  const entityDict = useSelector(getLookupList);
  const projectId = useSelector(getProjectId);
  const selectedDataSetId = useSelector(getSelectedDataSetId);

  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [_Id, setRowId] = useState(null);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [subRow, setSubRow] = useState(null);

  const slotDict = groupById(slotList || []);

  useEffect(() => {
    const lookupIdx = [];

    rowSlots.forEach(row => {
      const slot = slotDict[row.slotId];

      slot && slot.entityIds.forEach(e_id => {
        if (entityDict[e_id]?.entityType == "list" && !entityDict[e_id].values)
          !lookupIdx.includes(e_id)
            && lookupIdx.push(e_id);
      });
    });
    lookupIdx.forEach(lookupId => dispatch(fetchEntityValuesByLookupId({ lookupId })));

  }, [rowSlots, entityDict]);

  const handleRowItemChanged = props => {
    const { _id, ...entity } = props;
    putData(`/api/dataset/${selectedDataSetId}/row/${rowId}/entity/${_id}`, {
      entity,
    }, dispatch, data => {
      updateAnnotationRow(data.value);
    });
  };

  const handleDeleteRow = (modalState) => {
    setShowModal(true);
    setModalContent("Delete ...?");

    if (!modalState) return;

    setShowModal(false);
    if (modalState !== DIALOG_USER_STATE.AGREE || !_Id) return;

    ///api/dataset/:dataset/row/:rowid/entity/:entity`
    deleteData(`/api/dataset/${selectedDataSetId}/row/${rowId}/entity/${_Id}`, dispatch, data => {
      updateAnnotationRow(data.value);
    });
  };

  const handleAddRow = () => {
    if (!selectedTextObj)
      return;

    removeSplits(selectedTextObj);
    if (findIntersections({ dispatch, t, rowSlots, selectedTextObj, text }))
      return;

    postData(
      `/api/dataset/${selectedDataSetId}/row/${rowId}/entity`,
      { entity: { ...selectedTextObj, slotId: null, annotation: [] } },
      dispatch,
      data => updateAnnotationRow(data.value)
    );

    selectedTextObj.value = null; // instead of setSelectedTextObj(null);
  };

  const handleEditRow = (row) => {
    setSubRow(row);
    setEditDialogOpen(true);
  };

  const handleCloseEditRowDialog = (obj, isOpen) => {
    setEditDialogOpen(false);
    const { _id, start, end, annotation, slotId } = obj;

    if (obj.keyCode === 27 || !isOpen) return;

    const data = { annotation, end, start, slotId, _id };

    putData(`/api/dataset/${selectedDataSetId}/row/${rowId}/entity/${_id}`, {
      entity: data,
    }, dispatch, data => {
      updateAnnotationRow(data.value);
    });
  };

  //slot
  //entity
  //value
  return (
    <Fragment>
      <TableRow className={classes.tableRow}>
        <TableCell colSpan={2} className={classes.subcell}>
          &nbsp;
        </TableCell>
        <TableCell colSpan={4} className={classes.subcell}>
          {showModal && (
            <ConfirmDialog
              open={showModal}
              content={modalContent}
              closeModal={(modalState) => handleDeleteRow(modalState)}
            />
          )}
          {subRow && editDialogOpen && (
            <EditRowDialog
              row={subRow}
              onClose={handleCloseEditRowDialog}
            />
          )}
          <Grid
            container
            spacing={1}
            className={classes.tableHeader}
            style={{ fontSize: "0.7rem" }}
          >
            <Grid item xs={3} style={{ marginRight: "20px" }}>
              <div className={classes.paper}>{t("annotation.slot")}</div>
            </Grid>
            <Grid item xs={4}>
              <div className={classes.paper}>{t("annotation.entity_val")}</div>
            </Grid>
            <Grid item xs={3}>
              <div className={classes.paper}>{t("annotation.synonym")}</div>
            </Grid>
            <Grid item xs={1}></Grid>
          </Grid>
          {rowSlots.map((row, index) => {
            const synonym = annotationText(text, row);

            const setupSlotEntityValue = (s_id, update) => {
              const ok = (() => {
                if (!s_id)
                  return false;
                const slot = slotDict[s_id];
                if (!slot) {
                  showError(dispatch, t)('annotation.wrong_slot_id',{ id: s_id });
                  return false;
                }
                return slot.entityIds?.length == 1;
              })();
              if (!ok)
                return update([]);
              const entity = entityDict[slotDict[s_id].entityIds[0]];
              if (entity.values)
                update(addEntityAnnotationData({}, entity, synonym));
              else
                // not yet loaded
                getData(`/api/lookup/${entity._id}`, dispatch, data => {
                  update(addEntityAnnotationData({}, { ...entity, values: data.lookup.values }, synonym));
                });
            };

            const slot = slotDict[row.slotId];
            const entityIdsCount = slot?.entityIds?.length || 0;

            return (
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                key={index}
                spacing={2}
                className={classes.tableBody}
              >
                <Grid item xs={3} style={{ marginRight: "20px" }}>
                  <div className={classes.paper}>
                    <Autocomplete
                      size="small"
                      options={slotList || null}
                      getOptionLabel={option => option.name}
                      autoComplete
                      includeInputInList
                      value={slotList?.find(a => a._id == row.slotId) || null}
                      onChange={(event, value) => {
                        const slotId = value?._id || null;
                        setupSlotEntityValue(slotId, annotation => {
                          handleRowItemChanged({ ...row, slotId, annotation })
                        });
                      }}
                      renderInput={params => <TextField size="small" {...params} margin="normal"/>}
                    />
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <div className={classes.paper}>
                    {(() => {
                      if (entityIdsCount > 1)
                        return (
                          <div style={{ textAlign: "left" }}>
                            {(row.annotation || []).map(a => a.value).join("; ")}
                          </div>
                        );
                      if (entityIdsCount < 1)
                        return null;

                      // entityIdsCount == 1
                      const lookupId = slot.entityIds[0];
                      const entity = entityDict[lookupId];

                      if (!entity)
                        return `error: slot entity '${lookupId}' not found`;

                      if (entity.entityType == "list") {
                        return (
                          <Autocomplete
                            size="small"
                            options={entity.values || []}
                            filterOptions={addInputValue(value_text => `${t("common.add")} "${value_text}"`)}
                            getOptionLabel={getOptionLabelValue}
                            renderOption={option => option.value}
                            autoComplete
                            selectOnFocus
                            includeInputInList
                            value={entity.values?.find(v => v._id == row.annotation[0]?.entityId) || null}
                            onChange={(event, value) => {
                              if (value && value.inputValue) {
                                // Create a new value from the user input
                                dispatch(fetchAddToLookup({
                                  url: `/api/lookup/${lookupId}/value`,
                                  data: {
                                    value: {
                                      value: value.inputValue,
                                    },
                                    project: projectId,
                                  },
                                }, res => {
                                  if (res?.value) {
                                    dispatch(addToLookupDict({ lookupId, value: res.value }));
                                    const annotation = [{
                                      entityId: res.value._id || null,
                                      lookupId,
                                      value: res.value.value,
                                    }];
                                    handleRowItemChanged({ ...row, annotation });
                                  }
                                }));
                                return;
                              }

                              const annotation = [{
                                entityId: value?._id || null,
                                lookupId,
                                value: value?.value,
                              }];
                              handleRowItemChanged({ ...row, annotation });
                            }}
                            renderInput={params => (
                              <TextField size="small" {...params} margin="normal"/>
                            )}
                            freeSolo
                          />
                        );
                      }

                      return (
                        <TextField
                          size="small"
                          onBlur={event => {
                            const annotation = [{
                              lookupId: entity._id,
                              value: event.target.value || null,
                            }];
                            handleRowItemChanged({ ...row, annotation });
                          }}
                          variant="outlined"
                          fullWidth
                          defaultValue={row.annotation?.[0]?.value || null}
                        />
                      );
                    })()}
                  </div>
                </Grid>
                <Grid item xs={3}>
                  <div
                    className={classes.paper}
                    style={{ padding: 16, textAlign: "left" }}
                  >
                    {annotationText(text, row)}
                  </div>
                </Grid>
                <Grid item xs={1}>
                  <ButtonGroup>
                    <Tooltip title={t("annotation.edit_entity")}>
                      <CustomIconButton
                        disabled={entityIdsCount <= 1}
                        onClick={() => {
                          handleEditRow({
                            ...row,
                            entityDict,
                            entityIds: slot?.entityIds,
                            text: annotationText(text, row),
                          })
                        }}
                      >
                        <EditIcon size="small"/>
                      </CustomIconButton>
                    </Tooltip>
                    {(() => {
                      const lookupId = slot && slot.entityIds[0];
                      const entity = entityDict[lookupId];
                      const entityId = row.annotation[0]?.entityId;
                      const synonyms = entity?.values?.find(v => v._id == entityId)?.synonyms;
                      const canAddSynonym =
                        entity?.entityType == "list"
                        && entityIdsCount == 1
                        && entityId
                        && synonyms
                        && synonym
                        && !synonyms.includes(synonym);
                      return (
                        <Tooltip title={t("annotation.add_to_syn")}>
                          <CustomIconButton
                            disabled={!canAddSynonym}
                            onClick={() => {
                              dispatch(fetchUpdateInLookup({
                                  url: `/api/lookup/${lookupId}/value/${entityId}`,
                                  data: {
                                    value: {
                                      synonyms: synonyms.length > 0 ? [...synonyms, synonym] : [synonym],
                                    },
                                    project: projectId,
                                  },
                                }, res => {
                                  res?.value && dispatch(updateLookupDict({ lookupId, value: res.value }));
                                }
                              ));
                            }}
                          >
                            <PostAddIcon size="small"/>
                          </CustomIconButton>
                        </Tooltip>
                      );
                    })()}
                    <Tooltip title={t("common.delete")}>
                      <CustomIconButton
                        onClick={() => {
                          setRowId(row._id);
                          handleDeleteRow();
                        }}
                      >
                        <DeleteIcon size="small"/>
                      </CustomIconButton>
                    </Tooltip>
                  </ButtonGroup>
                </Grid>
              </Grid>
            );
          })}
          <Grid container spacing={4}>
            <Grid item xs={12}>
              {selectedTextObj?.value && (
                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  className={classes.margin}
                  onClick={handleAddRow}
                >
                  {selectedTextObj.value}
                </Button>
              )}
            </Grid>
          </Grid>
        </TableCell>
      </TableRow>
    </Fragment>
  );
}

function EditRowDialog({ onClose, row }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const slotList = useSelector(getSlotsList);
  const entityDict = useSelector(getLookupList);
  const projectId = useSelector(getProjectId);

  const [lookupId, setLookupId] = useState(null);
  const [value, setValue] = useState(null);
  const [prevValue, setPrevValue] = useState(null);
  const [switchOn, setSwitchOn] = useState(false);
  const [slotName, setSlotName] = useState(null);
  const [slotEntityOptions, setSlotEntityOptions] = useState([]);
  const [selectedTextObj, setSelectedTextObj] = useState(null);
  const [annotationList, setAnnotationList] = useState([]);

  useEffect(() => {
    if (row && slotList) {
      setLookupId(row.lookupId);
      setAnnotationList(row.annotation);

      const slot = slotList.find(slot => slot._id === row.slotId);      
      setSlotName(slot?.name);
      setSlotEntityOptions((slot?.entityIds || []).map(_id => ({ _id, name: entityDict[_id]?.name })));
    }
  }, [row]);

  const handleClose = save => () => {
    onClose({ ...row, annotation: annotationList }, save);
  };

  const addEntityAnnData = (data, entity_id, synonym) =>
    addEntityAnnotationData(data, entityDict[entity_id], synonym);

  //text, entities, value
  return (
    <Dialog
      fullWidth={true}
      maxWidth="lg"
      open={true}
      onClose={notBackdropClicked(handleClose(false))}
    >
      <CloseBar onClose={handleClose(false)} title={t("slots.edit_slot") + ": " + slotName}/>
      <DialogContent dividers>
        <HighLightTextBox
          style={{ border: "solid 1px" }}
          entities={annotationList}
          text={row.text}
          value={row.text}
          updateSelection={(event) => {
            const start = event.target.selectionStart;
            const end = event.target.selectionEnd;

            if (start !== end && end > 0)
              setSelectedTextObj({
                start: start,
                end: end,
                value: event.target.value.substring(start, end),
              });
            else setSelectedTextObj(null);
          }}
        />

        {annotationList.length && slotEntityOptions.length && (
          <Fragment>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              spacing={1}
            >
              <Grid item xs={3}>
                {t("entities.entity")}
              </Grid>
              <Grid item xs={4}>
                {t("entities.val")}
              </Grid>
              <Grid item xs={4}>
                {t("entities.syn")}
              </Grid>
              <Grid item xs={1} style={{textAlign: "center"}}>
                {t("common.action")}
              </Grid>
            </Grid>
            {annotationList.map((annotation, index) => {
              const entity_id = annotation.lookupId;
              const selectedEntityOption = entity_id
                ? slotEntityOptions.find(o => o._id == annotation.lookupId)
                : slotEntityOptions[index];
              if (!selectedEntityOption)
                return `error: no entity for this annotation (entity_id == '${entity_id}')`;
              const entity = entityDict[selectedEntityOption._id];
              if (!entity)
                return `error: entity '${JSON.stringify(selectedEntityOption)}' not found!`;
              const isListEntity = entity.entityType == 'list';

              const synonym = annotationText(row.text, annotation);
              const entity_value_id = annotation.entityId;
              const entity_value = isListEntity
                ? (entity_value_id
                    // Note: entity.values is created in a useEffect in AnnotationSubTable
                    //       and may be missing on the firs trender of EditRowDialog
                  ? (entity.values || []).find(v => v._id == entity_value_id)
                  : getListEntityValue(entity, synonym))
                : undefined;
              const updateAnnotation = d => {
                setAnnotationList(annotationList.map((a, i) => i == index ? { ...a, ...d } : a));
              };
              return (
                <Grid
                  key={index}
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item xs={3}>
                    <Autocomplete
                      size="small"
                      options={slotEntityOptions}
                      getOptionLabel={option => option.name}
                      disableClearable
                      includeInputInList
                      value={selectedEntityOption}
                      onChange={(event, value) => updateAnnotation(addEntityAnnData({}, value._id, synonym))}
                      renderInput={(params) => (
                        <TextField
                          size="small"
                          {...params}
                          variant="outlined"
                          label={t("entities.entity")}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    {isListEntity ? (
                      <Autocomplete
                        size="small"
                        options={entity.values}
                        filterOptions={addInputValue(value_text => `${t("common.add")} "${value_text}"`)}
                        getOptionLabel={getOptionLabelValue}
                        renderOption={option => option.value}
                        // selectOnFocus
                        disableClearable
                        includeInputInList
                        value={entity_value || null}
                        onChange={(event, value) => {
                          if (!value.inputValue)
                            return updateAnnotation({ entityId: value?._id, value: value?.value });

                          // Create a new value from the user input
                          dispatch(fetchAddToLookup({
                            url: `/api/lookup/${entity._id}/value`,
                            data: {
                              value: { value: value.inputValue },
                              project: projectId,
                            },
                          }, res => {
                            if (res?.value) {
                              dispatch(addToLookupDict({ lookupId: entity._id, value: res.value }));
                              updateAnnotation({ entityId: res.value?._id, value: res.value?.value });
                            }
                          }));
                        }}
                        renderInput={params => (
                          <TextField
                            size="small"
                            {...params}
                            variant="outlined"
                            label={t("entities.val")}
                          />
                        )}
                      />
                    ) : (
                      <TextField
                        size="small"
                        variant="outlined"
                        fullWidth
                        defaultValue={annotation.value || null}
                        onBlur={event => updateAnnotation({ entityId: null, value: event.target.value })}
                      />
                    )}
                  </Grid>
                  <Grid item xs={4}>
                    {synonym}
                  </Grid>
                  <Grid item xs={1}>
                    {(() => {
                      if (isListEntity && entity_value) {
                        const synonyms = entity_value.synonyms;

                        if (synonyms && synonym && !synonyms.includes(synonym)) {
                          return (
                            <Tooltip title={t("annotation.add_to_syn")}>
                              <IconButton
                                style={{ marginRight: 10 }}
                                size="small"
                                onClick={() => {
                                  dispatch(fetchUpdateInLookup({
                                    url: `/api/lookup/${entity._id}/value/${entity_value_id}`,
                                    data: {
                                      value: {
                                        synonyms: synonyms?.length > 0 ? [...synonyms, synonym] : [synonym],
                                      },
                                      project: projectId,
                                    },
                                  }, res => {
                                    res?.value && dispatch(updateLookupDict({
                                      lookupId: entity._id,
                                      value: res.value,
                                    }));
                                  }));
                                }}
                              >
                                <PostAddIcon size="small"/>
                              </IconButton>
                            </Tooltip>
                          );
                        }
                      }
                      return <div style={{ width: 30, display: "inline-flex" }}></div>;
                    })()}
                    <IconButton
                      size="small"
                      onClick={() => setAnnotationList(annotationList.filter((a, i) => i != index))}
                    >
                      <DeleteIcon/>
                    </IconButton>
                  </Grid>
                </Grid>
              );
            })}
          </Fragment>
        ) || null}
      </DialogContent>
      <DialogActions>
        {selectedTextObj && (
          <Button
            variant="contained"
            size="small"
            color="primary"
            onClick={() => {
              const num_ents = slotEntityOptions.length;
              if (annotationList.length >= num_ents) {
                setSelectedTextObj(null);
                return showError(dispatch, t)('annotation.no_more_entities',
                  { num_ents, slot: slotName, e: getEnding('entity', num_ents, t) });
              }

              removeSplits(selectedTextObj);
              if (findIntersections({ dispatch, t, rowSlots: annotationList, selectedTextObj, text: row.text }))
                return;

              const synonym = annotationText(row.text, selectedTextObj);
              const entity_id = slotEntityOptions[annotationList.length]._id;
              setAnnotationList([ ...annotationList, addEntityAnnData(selectedTextObj, entity_id, synonym) ]);
              setSelectedTextObj(null);
            }}
          >
            {selectedTextObj.value}
          </Button>
        )}
        <Button onClick={handleClose(false)} color="primary">
          {t("common.cancel")}
        </Button>
        {/* eslint-disable-next-line no-undef */}
        <Button onClick={handleClose(true)} color="primary" autoFocus>
          {t("common.save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export function HighLightTextBox(props) {
  const { updateSelection, onSaveChanges, colorBySlot, text: _text, entities: _ents, audio } = props;
  const classes = useStyles();
  const [height, setHeight] = useState("auto");
  const [text, setText] = useState(_text);
  const [entities, setEntities] = useState(_ents);
  const slotsLists = useSelector(getSlotsList);

  useEffect(() => { setEntities(_ents); },  [_ents]);

  const measuredRef = useCallback((node) => {
    if (node !== null) {
      // node.getBoundingClientRect(); //all params of textarea from Dome
      setHeight(node.scrollHeight);
    }
  }, []);

  const insert = (text, idx, str) => text.slice(0, idx) + str + text.slice(idx);
  const setColor = index => `style="background-color:${COLORS[index % COLORS.length]}"`;

  const prepareString = (entities) => {
    if (!text.length) return ["",""];
    let txt = text;

    entities
      .filter(e => e.start >= 0 && e.end > e.start)
      .sort((a,b) => b.start - a.start)
      .forEach((e,i) => {
        let j = i;
        if (colorBySlot) {
          const slotIndex = slotsLists?.findIndex(slot => slot._id == e.slotId);
          if (slotIndex >= 0)
            j = slotIndex;
        }
        txt = insert(txt, e.end, "</mark>");
        txt = insert(txt, e.start, `<mark ${setColor(j)}>`);
      });

    return [txt, txt.replace(/[<]br>/g, "&lt;br&gt;")]; // [<] avoids breaking syntax highlighting in Vim with vim-jsx-pretty
  };

  const [h,he] = prepareString([...entities]);

  const onChange = e => {
    setText(e.target.value);
    setEntities([]);
    updateSelection(e);
  };

  const onBlur = () => {
    if (onSaveChanges && _text !== text) {
      onSaveChanges({ text, entities });
    }
  };

  return (
    <div className={classes.highLightContainer}>
      <div className={classes.backdrop}>
        <div
          className={classes.highlight}
          dangerouslySetInnerHTML={{ __html: he }}
        ></div>
      </div>
      <textarea
        readOnly={!audio}
        ref={measuredRef}
        className={classes.highlightTextArea}
        style={{ height: height }}
        onClick={updateSelection}
        onChange={onChange}
        onBlur={onBlur}
        defaultValue={h.replace(/<\/?mark[^>]*?>/g,'')}
      ></textarea>
    </div>
  );
}

function EditDialog(props) {
  const { onClose, headCells, isGenerative } = props;
  const [intentId, setIntentId] = useState(null);
  const [text, setText] = useState(null);
  const [input, setInput] = useState(null);
  const [output, setOutput] = useState(null);
  const { t } = useTranslation();

  const setData = {
    input: setInput,
    output: setOutput,
  };

  const handleClose = save => () => {
    onClose(isGenerative ? { input, output } : { intentId, text }, save);
  };

  return (
    <Dialog
      fullWidth={true}
      open={true}
      onClose={notBackdropClicked(handleClose())}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
    >
      <CloseBar onClose={handleClose()} title={t("annotation.add_new_anno")}/>
      <DialogContent dividers style={{ overflow: "hidden" }}>
        {isGenerative
          ? ['input', 'output'].map(k => (
            <TextField
              key={k}
              style={{ margin: 8 }}
              size="small"
              id={k}
              label={t("annotation."+ k)}
              onChange={event => setData[k](event.target.value)}
              variant="outlined"
              fullWidth
              multiline
            />
          )) : (<>
            <Autocomplete
              size="small"
              fullWidth
              label={t("annotation.intent")}
              style={{ margin: 8 }}
              options={headCells?.find(h => h._id == "intentId")?.comboData || []}
              getOptionLabel={(option) => option.label}
              renderOption={(option, state) =>
                <Typography selected={state.selected}>
                  {option.title ?
                    <Tooltip title={
                      <h3>{option.title}</h3>}>
                      <span>{option.label}</span>
                    </Tooltip> :
                    <span>{option.label}</span>
                  }
                </Typography>
              }
              onChange={(event, value) => {
                setIntentId(value ? value.value : null);
              }}
              autoComplete
              includeInputInList
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                />
              )}
            />
            <TextField
              style={{ margin: 8 }}
              size="small"
              id="text"
              label={t("annotation.text")}
              onChange={(event) => setText(event.target.value)}
              variant="outlined"
              fullWidth
              multiline
            />
          </>)}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose()} color="primary">
          {t("common.cancel")}
        </Button>
        {/* eslint-disable-next-line no-undef */}
        <Button onClick={handleClose(true)} color="primary" autoFocus>
          {t("common.save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

/** for a given dataset we find
 * 1. latest trained NLU model (with max version or without version) based on this dataset
 * 2. reference NLU model (with max deploy time or max version, as with latest)
 * 3. latest NLU model' latest ready test
 * 4. reference NLU model' latest ready test
 */
function getComparingTestModels(testModelList, dataset, defaultBatches) {
  if (!testModelList?.testmodels || !testModelList.models || !defaultBatches)
    return [];
  const trained_on_dataset = m => m.trainSet?.length == 1 && m.trainSet[0] == dataset;
  const by_key = k => (a,b) => !a[k] ? (b[k] ? -1 : 0) : !b[k] ? 1 : a[k] < b[k] ? -1 : a[k] > b[k] ? 1 : 0;
  const by_version = by_key('version');
  const models = testModelList.models.filter(trained_on_dataset);
  function P(...args) { getRunConfigOption('getComparingTestModelsVerbose') && console.log(...args) }
  P('--------------------------------------------------------------');
  P('dataset',dataset);
  P('models',models);
  const new_model = models.find(m => m._id == defaultBatches.SPLIT_TRAIN_TEST.nlu_model_2);
  const ref_model = models.filter(m => m.deployedAt).sort(by_key('deployedAt')).reverse()[0];
  P('ref/new model: ',ref_model, new_model);
  const ready = tm => tm?.status == 'Ready';
  const testmodels = testModelList.testmodels.filter(ready);
  P('testmodels',testmodels);
  const by_model = m => tm => tm.model == m?._id;
  const ref_test = ref_model && testmodels.find(tm => tm.model == ref_model._id);
  const new_test = new_model && testmodels.find(tm => tm._id == defaultBatches.SPLIT_TRAIN_TEST.testmodel);
  P('ref/new test: ',ref_test, new_test/*, defaultBatches*/);
  return [ref_test, new_model?.deployedAt ? undefined : new_test, new_test];
}

function ConfirmTrainTestDialog(props) {
  const { isOpen, close, runTrainTest, rows, testId, testModelList, selectedDataSetId } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const tt = i => t("annotation.hd__"+ i);
  const d = {};
  const tm = (testModelList?.testmodels || []).find(t => t._id == testId);
  const [ samplesTotal,   setSamplesTotal   ] = useState(tt('loading')+'...');
  const [ samplesChecked, setSamplesChecked ] = useState(tt('loading')+'...');
  const [ intentsTrain,   setIntentsTrain   ] = useState(null);
  const [ intentsTest,    setIntentsTest    ] = useState(tm ? null : undefined);

  d.samples_total = samplesTotal;
  d.samples_checked = samplesChecked;
  if ((testModelList?.models || []).find(m => m._id == tm?.nlu_model_2)?.type != 'ASR_E2E') {
    d.intents_train = intentsTrain ? intentsTrain.length : tt('loading')+'...';
    d.intents_test = intentsTest ? intentsTest.length : intentsTest === null ? tt('loading')+'...' : '...'+tt('loading');
  }

  const fetchIntentsStat = (dataset, action) =>
    getData(`/api/dataset/${dataset}/stat?show=intents,rows`, dispatch, action);

  useEffect(() => {
    if (rows.length)
      fetchIntentsStat(selectedDataSetId, data => {
        setSamplesTotal(data.rows.total);
        setSamplesChecked(data.rows.checked);
        setIntentsTrain(data.intents);
      });
  }, [rows.length, rows.filter(r => r.status == 'manual').length]);

  useEffect(() => {
    if (tm && intentsTrain)
      fetchIntentsStat(tm.dataset, data => {
        const ints_train = new Set(intentsTrain);
        setIntentsTest(data.intents.filter(i => ints_train.has(i)));
      });
  }, [tm && intentsTrain]);

  return (
    <ConfirmDialog
      fullWidth={true}
      open={isOpen}
      title={tt("train_test")}
      btnNameDisagree={t("common.cancel")}
      btnNameAgree={tt("run")}
      content={
        <table style={{width: '100%'}}>
          <tbody>
            {Object.keys(d).map(k => <tr key={k}><td>{tt(k)}</td><td>{d[k]}</td></tr>)}
          </tbody>
        </table>
      }
      closeModal={s => {s == DIALOG_USER_STATE.AGREE && runTrainTest(); close()}}
    />
  );
}

function viewAudio(row, cell) {
  if (!row[cell._id])
    return null;
  const useStyles = makeStyles((theme) => {
    const small = { width: 30, height: 'auto', /* FIXME */ marginTop: -100, marginLeft: 0, color: 'grey' };
    return {
      playIcon: small,
      replayIcon: small,
      pauseIcon: small,
    };
  });
   return (
    <AudioPlayer
      elevation={0}
      width={0}
      height={0}
      variation="primary"
      volume={false}
      displaySlider={false}
      useStyles={useStyles}
      src={row[cell._id]}
    />
  );
}

function ChangeStatusDialog(props) {
  const { onClose } = props;
  const [changeAll, setChangeAll] = useState(false);
  const [newStatusValue, setNewStatusValue] = useState(null);

  const comboStatusData = ['auto', 'manual'].map(s => ({ value: s, label: s }));
  const { t } = useTranslation();

  return (
    <ConfirmDialog
      title={t("annotation.change_status")}
      btnNameDisagree={t("common.cancel")}
      btnNameAgree={t("common.save")}
      closeModal={(modalState) => onClose(modalState, newStatusValue, changeAll ? 'all' : 'page')}
      content={
        <>
          <Autocomplete
            options={comboStatusData}
            onValueChange={setNewStatusValue}
          />
          <FormControlLabel
            style={{ margin: 8, height: "auto" }}
            control={<Checkbox/>}
            label={t("annotation.for_all")}
            checked={changeAll}
            onChange={event => setChangeAll(Boolean(event.target.checked))}
          />
        </>}
    />);
}

function DownloadSelect(props) {
  const { selectedDataSetId, dispatch } = props;
  const classes = useStyles();
  return (
    <div>
      <Tooltip title='download'>
      <FormControl>
        <Select
          className={classes.downloadSelect}
          IconComponent={GetAppIcon}
        >
          {['json', 'csv'].map(k => 
          <MenuItem key={k} onClick={() => download(`/api/dataset/${selectedDataSetId}/download?format=${k}`, dispatch)}>
            {k.toUpperCase()}
          </MenuItem>)}
        </Select>
      </FormControl>
        </Tooltip>
    </div>
  );
}

function CustomAutocomplete(props) {
  const { t } = useTranslation();
  const { cell, row, handleRowItemChanged, intents } = props;

  const classes = useStyles();

  const IntentGroupType = Object.freeze({
    Best: "annotation.best_intents",
    Other: "annotation.other_intents",
  });

  const hasIntentRanking = row.intentRanking?.length && row.intentRanking.every(r => r.confidence);
  const bestIntentsIds = hasIntentRanking && row.intentRanking.map(intent => intent.id);
  const bestIntents = hasIntentRanking && row.intentRanking
    .map(intent => {
      return {
        type: t(IntentGroupType.Best),
        value: intent.id,
        label: intents?.[intent.id] || '???',
        confidence: Number(intent.confidence).toFixed(3),
      };
    })
    .sort((a, b) => b.confidence - a.confidence);

  const allIntents = cell.comboData
    ?.filter(intent => !(bestIntentsIds && bestIntentsIds.includes(intent.value)))
    .map(intent => ({ type: t(IntentGroupType.Other), value: intent.value, label: intent.label, title: intent.title }));

  const options = bestIntents ? [...bestIntents, ...allIntents] : allIntents;

  const CustomPopper = function (props) {
    return <Popper {...props} style={{ width: "fit-content", backgroundColor: "#fff" }} placement='bottom-start'/>;
  };

  const renderOption = (option) => {
      return (
        <Grid
          container
          direction="row"
          wrap="nowrap"
          spacing={2}
          justify="flex-start"
          alignItems="center"
        >
          {option.confidence?
          <>
            <Grid item>
              <Tooltip title={option.title ?
                  option.title : ""}>
                <Typography noWrap align="left">{option.label}</Typography>
              </Tooltip>
            </Grid>
            <Grid item xs>
              <Typography noWrap align="right">{option.confidence}</Typography>
            </Grid>
          </>:
          <Grid item>
            <Tooltip title={option.title ?
              option.title : ""}>
              <Typography noWrap align="left">{option.label}</Typography>
            </Tooltip>
          </Grid>}
        </Grid>
      );
  };

  const descr = (cell.comboData || []).find(a => a.value === row[cell._id]);
  const getTitle = i => i?.name + (i?.description ? ' // '+ i.description : '');
  const title = getTitle({ name: descr?.label, description: descr?.title }); // as in ReportDialog

  return (
      <Tooltip
        title={title? title : ''}>
        <CoreAutocomplete
          size="small"
          style={{ fontSize: "0.875rem", width: "100%" }}
          options={options}
          filterOptions={(options, state) => [...options.filter(o => o.label == state.inputValue), ...options.filter(o => o.label != state.inputValue)].filter(o => o.label.includes(state.inputValue))}
          groupBy={hasIntentRanking ? (option) => option.type : null}
          getOptionLabel={(option) => option.label}
          disableClearable
          autoComplete
          autoHighlight
          PopperComponent={CustomPopper}
          fullWidth
          renderOption={renderOption}
          value={(options.find(a => a.value === row[cell._id])) || null}
          onChange={(event, value) => {
            handleRowItemChanged({ ...row, [cell._id]: value?.value || null, rowName: cell._id });
          }}
          onInputChange={(event, value) => {}}
          renderInput={(params) => {
            const newParams = { ...params, InputProps: { ...params.InputProps, style: { fontSize: "0.875rem" } } };

            return <TextField className={classes.underlinedInput} {...newParams} size="small" fullWidth variant="standard" />;
          }}
        />
      </Tooltip>
  );
};

class Annotation extends React.Component{
  constructor(props) {
    super(props);

    const {
      projectDatasets,
      selectedDataSetId,
      lang,
    } = props;

    this.state = {
      rows: [],
      showModal: false,
      showChangeStatusDialog: false,
      chosenScreen: "annotation",
      modalContent: null,
      rowId: null,
      editDialogOpen: false,
      slot: null,
      showSimilar: null,
      currentRow: null,
      annotationId: "",
      editSubRow: false,
      showTestReport: null,
      showConfirmTrainTest: false,
      importFromDC: false,
      trainTest: false,
      deploying: false,
      batchStatus: null,
      loadingDefaultBatches: false,
      defaultBatches: null,
      showDefaultBatchesToolbar: false,
      projectDatasets,
      lang,
      isExpanded: false,
      paging: {}, // {"page": 1, "limit": 3, "sort": {"text": 1}}
      pagingListInfo: {},
    };

    this.scrollable = new Scrollable(this);
  };

  getProject = () => this.props.projects.find(p => p._id == this.props.projectId);

  isASR =         () => isMaybeASR(this.getProject());
  isNLU =         () => isMaybeNLU(this.getProject());
  isGenerative =  () => isGAI(this.getProject());

  getDataset = () => this.props.projectDatasets.find(d => d._id == this.props.selectedDataSetId);

  getHeadCells = () => {
    const { t, slotsList, intentsList, } = this.props;
    if (!slotsList || !intentsList)
      return null;
    const comboStatusData = ['auto', 'manual', 'none'].map(s => ({ value: s, label: s }));
    return [
        {
          _id: "rowNo",
          label: "#",
          width: "5%",
        },
        ...(this.isGenerative() ? [
          {
            _id: "input",
            label: t("annotation.input"),
            width: "auto",
            align: "left",
            multiline: true,
            textSearch: true,
            limit: 350,
          },
          {
            _id: "output",
            label: t("annotation.output"),
            width: "auto",
            align: "left",
            customTextField: true,
            multiline: true,
            textSearch: true,
            limit: 150,
          },
        ] : [
          ...(this.isASR() ? [{
            _id: "audio",
            label: t("annotation.audio"),
            width: "5%",
            sortable: false,
          }] : []),
          ...(this.isNLU() ? [{
            _id: "intentId",
            label: t("annotation.intent"),
            width: "20%",
            filterOn: true,
            comboData: intentsList?.map(intent => {
              return { value: intent._id, label: intent.name, title: intent.description };
            }),
          }] : []),
        ]),
        {
          _id: "confidence",
          label: t("annotation.confidence"),
          width: "5%",
          filterOn: true,
          filterType: 'threshold',
        },
        ...(this.isGenerative() ? [] : [
          {
            _id: "text",
            label: t("annotation.text"),
            width: "auto",
            align: "left",
            filterOn: this.isNLU(),
            filterTooltip: t("annotation.filter_slots"),
            comboData: slotsList?.map(slot => ({ value: slot._id, label: slot.name })),
            textSearch: true,
          },
        ]),
        {
          _id: "status",
          label: t("common.status"),
          width: "10%",
          align: "center",
          filterOn: true,
          comboData: comboStatusData,
          customFilter: '// set up later //', // can't assign to a React component here due to headCells being
                                              // passed to redux storage (see above), that needs serialization ability
          sortable: false,
        },
      ];
  };

  fetchData() {
    const { dispatch, projectId } = this.props;

    dispatch(fetchIntents(projectId));
    dispatch(fetchSlots(projectId));
    dispatch(fetchTestModelList(projectId));
  }

  componentDidMount() {
    this.fetchData();
    this.scrollable.componentDidMount();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.projectId != this.props.projectId)
      return this.fetchData();

    this.intents = this.props.intentsList?.reduce((a,i) => ({ ...a, [i._id]: i.name }), {});

    const { dispatch, projectId, selectedDataSetId } = this.props;
    if (prevProps.projectId != projectId || prevProps.selectedDataSetId != selectedDataSetId)
      this.setState({ showSimilar: false });

    const trained_on_dataset = m => m.trainSet?.length == 1 && m.trainSet[0] == selectedDataSetId;
    const models = (this.props.testModelList?.models || []).filter(trained_on_dataset).map(m => m._id);

    if (!models)
      return;

    if (this.state.loadingDefaultBatches) {
      if (this.props.selectedDataSetId !== prevProps.selectedDataSetId || this.state.defaultBatches) {
        const show = this.state.defaultBatches && models.includes(this.state.defaultBatches.SPLIT_TRAIN_TEST.nlu_model_2);
        
        if (show != this.state.showDefaultBatchesToolbar) // dataset has been changed
          this.setState({ showDefaultBatchesToolbar: show });
      }
      return;
    }

    this.setState({ loadingDefaultBatches: true });

    getData(`/api/batch?project=${projectId}`, dispatch, data => {
      const b = data.batches.find(b => b.type == 'SPLIT_TRAIN_TEST' && b.default);
      b && this.setState({
        defaultBatches: { SPLIT_TRAIN_TEST: b },
        showDefaultBatchesToolbar: models.includes(b.nlu_model_2),
      });
    });
  }

  componentWillUnmount() {
    this.abortController.abort();
  }

  abortController = new window.AbortController();

  viewIntent = (row, cell, { setSelectedTextObj }) => (
    <CustomAutocomplete
      cell={cell}
      row={row}
      handleRowItemChanged={this.delayedHandleRowItemChanged}
      intents={this.intents}
    />
  );

  viewText = (row, cell, { setSelectedTextObj }) => {
    return /* !row.text ? '---' : */ (
      <HighLightTextBox
        {...row}
        value={row[cell._id]}
        colorBySlot={true}
        updateSelection={(event) => {
          const start = event.target.selectionStart;
          const end = event.target.selectionEnd;
          if (start != end) {
            setSelectedTextObj({
                               rowId: row._id,
                               start: start,
                               end: end,
                               value: event.target.value.substring(start, end),
            });
            this.onExpand(row._id, true);
          } else {
            setSelectedTextObj(null);
          }
        }}
        onSaveChanges={obj => this.handleRowItemChanged({...row, ...obj})}
      />
    );
  };

  // FIXME: remove, use confidence value from BE
  CONFIDENCE_PRECISION = 3;
  getConfidenceValue = row => this.isGenerative()
    ? row.confidence
    : row.intentRanking?.find(ri => row.intentId == ri.id)?.confidence;

  showConfidenceValue = c => c ? Number(c.toFixed(this.CONFIDENCE_PRECISION)) : undefined;

  fetchRows = _paging => {
    const { dispatch, projectId } = this.props;
    const paging = _paging || this.state.paging;

    return postData(
      `/api/datasetrow`,
      { ...paging, confidence_precision: this.CONFIDENCE_PRECISION },
      dispatch,
      data => {
        const { docs, ...pagingListInfo } = data;
        this.setState({ projectId, paging, pagingListInfo, rows: docs.map(row => ({
          ...row,
          confidence: this.showConfidenceValue(row.confidence),
        }))});
      });
  };

  updateAnnotationRow = row => {
    row && this.setState({ rows: this.state.rows.map(r => {
      const item = r._id == row._id ? row : r;
      return { ...item, confidence: this.showConfidenceValue(this.getConfidenceValue(item)) };
    })})
  };

  removeAnnotationEntity = row_id => {
    this.setState({ rows: this.state.rows.filter(r => r._id != row_id)})
  };

  handleDeleteRow = (id, name) => {
    const { t } = this.props;
    this.setState({
      modalContent: `${t("annotation.delete_anno")}`,
      showModal: true,
      rowId: id,
    });
  };

  handleCloseModal = (modalState) => {
    this.setState({ showModal: false });

    if (modalState === DIALOG_USER_STATE.AGREE)
      deleteData(`/api/dataset/${this.props.selectedDataSetId}/row/${this.state.rowId}`, this.props.dispatch, data => {
        this.removeAnnotationEntity(this.state.rowId);
      });
  };

  handleClickNewRow = () => {
    this.setState({ editDialogOpen: true });
  };

  handleRowItemChanged = async (obj) => {
    const { dispatch, selectedDataSetId } = this.props;
    //PUT /api/dataset/:dataset/row/:id
    const rowId = obj._id;
    if (obj.rowName == 'status')
      return this.handleCloseChangeStatus({ value: obj.status }, 'one', rowId);
    delete obj.rowName;
    delete obj._id;
    putData(`/api/dataset/${selectedDataSetId}/row/${rowId}`, {
      row: obj
    }, dispatch, data => {
      this.updateAnnotationRow(data.value);
    });
  };

  // delayedHandleRowItemChanged = delay(this.handleRowItemChanged, 300); - what was it for??
  delayedHandleRowItemChanged = this.handleRowItemChanged;

  handleCloseEditDialog = (row, save) => {
    this.setState({ editDialogOpen: false });
    if (save) {
      const { dispatch, selectedDataSetId } = this.props;
      postData(`/api/dataset/${selectedDataSetId}/row`, { row }, dispatch, data => {
        this.fetchRows();
      });
    }
  };

  onExpand = (_id, stillOpen) => {
    this.props.dispatch(setExpandedList({ _id, stillOpen }));
  };

  onExpandAll = () => {
    this.setState({ isExpanded: true });
    this.props.dispatch(expandAllRows(this.state.rows.map((row) => row._id)));
  };

  onCollapseAll = () => {
    this.setState({ isExpanded: false });
    this.props.dispatch(collapseAllRows());
  };

  handleCloseChangeStatusDialog = (modalState, newStatusValue, ...args) => {
    this.setState({ showChangeStatusDialog: false });
    if (modalState == DIALOG_USER_STATE.AGREE && newStatusValue)
      this.handleCloseChangeStatus(newStatusValue, ...args);
  };

  handleCloseChangeStatus = (newStatusValue, changeSet, rowId) => {
    const { dispatch } = this.props;
    const row_ids = {
      all:  {},
      one:  { only_rows: [rowId] },
      page: { only_rows: this.state.rows.map(r => r._id) },
    };
    putData(`/api/dataset/${this.props.selectedDataSetId}/status/${newStatusValue.value}`, {
      username: this.props.userName,
      ...row_ids[changeSet],
    }, dispatch, data => {
      this.setState({ rows: this.state.rows.map(r => r._id == rowId ? ({ ...r, status: newStatusValue.value }) : r)})
    });
  };

  showSimilar = row => {
    this.setState({ showSimilar: true, currentRow: row });
  };

  areVectorsBuilt = () => this.getDataset()?.execution?.status == 'Vectorized';

  watchStatus = (batch_id, name) => {
    const { dispatch } = this.props;
    this.setState({ [name]: true });
    const my = this;
    const d0 = 4;
    let d = d0;
    function checkStatus() {
      getData(`/api/batch/${batch_id}`, dispatch, data => {
        const s = data.batch.execution.status;
        my.setState({ batchStatus: s});
        if (!s || s == 'Ready' || s == 'Error' || d > 500) {
          setTimeout(() => my.setState({ batchStatus: null, [name]: false}), 2000);
          my.props.dispatch(fetchTestModelList(my.props.projectId));
          if (s == 'Error') 
            throw new Error(data.batch.execution.error || 'api error');
          return;
        }
        setTimeout(checkStatus, d*1000);
        d = s == my.state.batchStatus ? d*2 : d0;
      });
    }
    setTimeout(checkStatus, d*1000);
  };

  runLoadFromDC = () => {
    const { projectId, dispatch } = this.props;

    getData(`/api/project/${projectId}/run_batch_load_data`, dispatch, data => {
      dispatch(
        setMessageState({
          snackBarMessages: "Load from DC started...",
          snackBarVariant: MESSAGE_STATUS.SUCCESS,
          snackBarState: true,
        }),
      );
      this.importFromDCGet(data.batch);
    });
  };

  importFromDCGet = (batch_id) => {
    const { dispatch } = this.props;

    getData(`/api/batch/${batch_id}/get_output_dataset`, dispatch, data => {
      this.fetchRows();
      dispatch(changeDataset(data.dataset));
      this.watchStatus(batch_id, 'importFromDC');
    });
  };

  confirmTrainTest = () => {
    this.setState({ showConfirmTrainTest: true });
  }

  runTrainTest = () => {
    const { dispatch, projectId } = this.props;

    getData(`/api/project/${projectId}/run_batch_split_train_test`, dispatch, data => {
      this.fetchRows();
      this.watchStatus(data.batch, 'trainTest');
    });
  };

  deploy = () => {
    const { dispatch, projectId } = this.props;

    getData(`/api/project/${projectId}/run_batch_split_deploy`, dispatch, data => {
      dispatch(
        setMessageState({
          snackBarMessages: "Deploy started...",
          snackBarVariant: MESSAGE_STATUS.SUCCESS,
          snackBarState: true,
        }),
      );
      this.watchStatus(data.batch, 'deploying');
    });
  };

  // RENDER=0;
  render() {
    // console.log('RENDER', this.RENDER++, this.props.projectId, this.isGenerative());
    const {
      rows: _rows,
      modalContent,
      showModal,
      editDialogOpen,
      showChangeStatusDialog,
      showTestReport,
      defaultBatches,
    } = this.state;

    const {
      projectId,
      isExpanded,
      pagingList,
      t,
      testModelList,
      selectedDataSetId,
      intentsList,
    } = this.props;

    /* When project is changed on Annotation tab, first re-renders have previous project's rows,
     * which might be incompatible with current project render rules (ex: Generative -> NLU)
     */
    const rows = this.state.projectId == projectId ? _rows : [];

    const __headCells = this.getHeadCells();
    if (!__headCells)
      return null;

    const scf = (
      <Tooltip title={t("annotation.change_status")}>
        <IconButton size="small" onClick={() => this.setState({ showChangeStatusDialog: true })}>
          <FilterListIcon fontSize="small"/>
        </IconButton>
      </Tooltip>
    );
    const _headCells = __headCells.map(c => c._id == 'status' ? { ...c, customFilter: scf } : c);

    if (!selectedDataSetId)
      return 'No datasets';
    // if (selectedDataSetId == 'undefined') // FIXME: prevent 'undefined' appearing as string
    //   return '';

    const edb = getRunConfigOption('enableDefaultBatches');
    const [ refTest, newTest, newTestFromBatch ] = getComparingTestModels(testModelList, selectedDataSetId, edb && defaultBatches);
    const headCells = _headCells
      .filter(cell => cell._id != 'audio' || rows?.find(r => r.audio))
      .filter(cell => cell._id != 'confidence' || rows?.find(r => r.confidence) || rows && !rows.length);

      return (
        <Fragment>
          <Similarity
            open={this.state.showSimilar}
            currentRow={this.state.currentRow}
            dataset={this.getDataset()}
            scrollable={this.scrollable}
            handleChangeAnnotation={this.handleRowItemChanged}
            onClose={() => this.setState({ showSimilar: false })}
          />
          {showModal && (
            <ConfirmDialog
              title={t("annotation.confirm_title")}
              open={showModal}
              content={modalContent}
              closeModal={(modalState) => this.handleCloseModal(modalState)}
            />
          )}
          {showChangeStatusDialog &&
            <ChangeStatusDialog
              onClose={this.handleCloseChangeStatusDialog.bind(this)}
            />}
          {editDialogOpen && <EditDialog
            headCells={headCells}
            isGenerative={this.isGenerative()}
            onClose={this.handleCloseEditDialog}
          />}
          {showTestReport
              && (showTestReport == 'old_model' || newTest?.data?.int)
              && (showTestReport == 'new_model' || refTest?.data?.int) &&
            <ReportDialog {...{
              testModelId:          showTestReport == 'old_model' ? refTest?._id : newTest?._id,
              testModel2Id:         showTestReport == 'diff' ? refTest?._id : null,
              showEntityTab:        false,
              testModelList,
              intentsList,
              onClose:              () => { this.setState({ showTestReport: null }) },
            }} />}
          {this.state.showConfirmTrainTest && <ConfirmTrainTestDialog {...{
            rows,
            testId:               newTestFromBatch?._id,
            testModelList,
            selectedDataSetId,
            isOpen:               true,
            close:                () => this.setState({ showConfirmTrainTest: false }),
            runTrainTest:         this.runTrainTest,
          }}/>}
          {<EnhancedTable
              id="annotation"
              useLocationSearch
              open={!this.state.showSimilar}
              style={this.state.currentRow ? {display: 'none'} : {}}
              headCells={headCells}
              rows={rows}
              viewCell={{ intentId: this.viewIntent, audio: viewAudio, text: this.viewText }}
              toolBarName={t("menu.annotation")}
              handleClickNewRow={() => this.handleClickNewRow()}
              handleRowItemChanged={this.delayedHandleRowItemChanged}
              checkBoxTableCell={this.isNLU() ? (_id, name, index) => (
                <TableCell>
                  <IconButton onClick={() => this.onExpand(_id)} size="small">
                    {isExpanded.find((id) => id === _id) ? (
                      <ExpandLessIcon/>
                    ) : (
                      <ExpandMoreIcon/>
                    )}
                  </IconButton>
                </TableCell>
              ) : undefined}
              customBtns={(name, id, row) => (
                <div style={{width: "10%", display: "flex"}}>
                  {this.isNLU() ?
                  <IconButton
                    style={{marginRight: 10}}
                    size="small"
                    disabled={!this.areVectorsBuilt()}
                    onClick={() => this.showSimilar(row)}
                  >
                    <Tooltip size="small" title={t("annotation.similarity")}>
                      <ShareIcon size="small"/>
                    </Tooltip>
                  </IconButton> : null}
                  <Tooltip title={t("common.delete")}>
                    <IconButton
                      aria-label={t("common.delete")}
                      size="small"
                      onClick={() => this.handleDeleteRow(id, name)}
                    >
                      <DeleteIcon/>
                    </IconButton>
                  </Tooltip>
                </div>
              )}
              customHeaderButtons={(name, id) => {
                const tt = i => t("annotation.hd__"+ i);
                const h = this.props.lang == "ru" /* FIXME: need some const here */ ? 64 : 36;
                const s = { textAlign: 'left', width: 300 };
                const in_progress = this.state.importFromDC || this.state.trainTest || this.state.deploying;
                const is_expanded = Number(Boolean(this.state.isExpanded));
                const XD = [{
                  onClick: this.onExpandAll,
                  label: 'expand_all',
                  icon: <ExpandMoreIcon/>,
                }, {
                  onClick: this.onCollapseAll,
                  label: 'collapse_all',
                  icon: <ExpandLessIcon/>,
                }];

                return (
                  <>
                    {this.state.showDefaultBatchesToolbar ?
                    <>
                      <ButtonGroup style={{ marginLeft: 20 }}>
                        <Button
                          style={{ height: h, width: 180 }}
                          onClick={this.runLoadFromDC}
                          disabled={in_progress}
                        >
                          {this.state.importFromDC ? this.state.batchStatus || tt("in_progress") : tt("import_from_dc")}
                        </Button>
                        <Button
                          style={{ height: h, width: 180, ...(this.state.trainTest ? { color: 'blue'} : {}) }}
                          onClick={this.confirmTrainTest}
                          disabled={in_progress}
                        >
                          {this.state.trainTest ? this.state.batchStatus || tt("in_progress") : tt("train_test")}
                        </Button>
                      </ButtonGroup>
                      <div style={{fontSize: 12, textAlign: 'left', marginLeft: 10}}>
                        <table style={s}>
                          <tbody>
                            {[['old_model', refTest], ['new_model', newTest]].map(([key, test]) => (
                              <tr {...{
                                key,
                                title: test !== undefined ? testModelCompareName(testModelList?.models, false, test) : 'not-found'
                              }}>
                                <td>{tt(key)}:</td>
                                <td>INT: {test?.data?.int !== undefined ?
                                  <Link href="#" onClick={() => this.setState({ showTestReport: key }) }>
                                    {toFloat(test?.data.int.accuracy,4)}
                                  </Link> : '-------'}
                                </td>
                                <td>ENT: {test !== undefined ?
                                  <Link href="#" onClick={() => this.setState({ showTestReport: key }) }>
                                    {"-------"}
                                  </Link> : '-------'}
                                </td>
                                <td>ASR: {test?.data?.asr !== undefined ?
                                  <Link href="#" onClick={() => this.setState({ showTestReport: key }) }>
                                    {test?.data.asr.WRR}
                                  </Link> : '-------'}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                      <IconButton onClick={() => { this.setState({ showTestReport: 'diff' }) }}>
                        <CompareArrowsIcon/>
                      </IconButton>
                      <ButtonGroup>
                        <Button
                          style={{ height: h, width: 150 }}
                          onClick={this.deploy}
                          disabled={in_progress}
                        >
                          {this.state.deploying ? this.state.batchStatus || 'Deploying...' : tt("deploy")}
                        </Button>
                      </ButtonGroup>
                    </> : null}
                    {window.runConfig.downloadSelect ? // TODO: implement DownloadSelect properly
                      <DownloadSelect {...{ selectedDataSetId, dispatch: this.props.dispatch }}/> : null}
                    <div>
                      <IconButton disabled={!rows.length} onClick={XD[is_expanded].onClick.bind(this)}>
                        <Tooltip title={t("common." + XD[is_expanded].label)}>
                          {XD[is_expanded].icon}
                        </Tooltip>
                      </IconButton>
                    </div>
                  </>
                )}}
              fetchRows={this.fetchRows.bind(this)}
              pagingListInfo={this.state.pagingListInfo}
              expandedRows={({key, row, selectedTextObj, refresh}) => {
                return <AnnotationSubTable
                  key={key}
                  rowSlots={row.entities} // entities is a wrong legacy name in DB
                  rowId={row._id}
                  text={row["text"] || null}
                  selectedTextObj={selectedTextObj}
                  updateAnnotationRow={this.updateAnnotationRow.bind(this)}
                />
              }}
            />
          }
        </Fragment>
      );
  }
}

const mapStateToProps = state => ({
  projects: state.settings.projects,
  projectId: state.settings.projectId,
  projectDatasets: state.settings.projectDatasets,
  selectedDataSetId: state.settings.selectedDataSetId,
  intentsList: state.settings.intentsList,
  slotsList: state.settings.slotsList,
  isExpanded: state.annotationSettings.isExpanded,
  userName: state.settings.user.name,
  testModelList: state.settings.testModelList,
  lang: state.settings.lang,
});

export default withRouter(
  connect(mapStateToProps)(withTranslation()(Annotation)),
);
