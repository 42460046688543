import React, { useState, useEffect, useRef } from "react";
import { Switch, Link, Router, Route, Redirect } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import Box from "@material-ui/core/Box";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import LinearProgress from "@material-ui/core/LinearProgress";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import SettingsIcon from "@material-ui/icons/SettingsOutlined";
import Tooltip from "@material-ui/core/Tooltip";

import clsx from "clsx";

import SnackbarMessage    from "./snackbarMessage";
import LanguageSelect     from "./selectLang";
import InfoPopover        from "./infoPopover";
import SimpleBackdrop     from "./backDrop";
import SettingsDialog     from "./SettingsDialog";

import {
  parseLocation,
  initGlobals,
  changeProject,
  getProjects,
  getProjectId,
  getIsLoading,
  getProjectDatasets,
  getSelectedDataSetId,
  getLocationPath,
  changeDataset,
  setToken,
  getToken,
  getLang,
  getOauthServer,
}                                                       from "../../../features/settings";

import SignIn, { OAuthCallback }                        from "../../pages/signIn";

import Location                                         from "../../../core/location";
import {
  GetToken, GetUserName, IsAdmin, getRunConfigOption,
  showError, useTranslation, isGAI, isASR,
}                                                       from "../../../core/utils";
import { SOME_SETTINGS }                                from "../../../core/constants";

const drawerWidth = 160;

const fixScroll = {
  main: {
    overflow: "hidden",
  },
  container: {
    height: "97%",
    overflow: 'auto',
  },
};

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuItemAdmin: {
    color: "blue",
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
    textAlign: "left",
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: 0,
    // width: theme.spacing(7),
    // [theme.breakpoints.up('sm')]: {
    //   width: theme.spacing(9),
    // },
  },
  appBarSpacer: theme.mixins.toolbar,
  main: {
    flexGrow: 1,
    height: "100vh",
    ...fixScroll.main,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    ...fixScroll.container,
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  option: {
    color: "black",
    minHeight: "auto",
    alignItems: "flex-start",
    padding: 8,
    "&[aria-selected=\"true\"]": {
      backgroundColor: "transparent",
    },
    "&[data-focus=\"true\"]": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  rootAuto: {
    display: "inline-block",
  },
  inputAuto: {
    color: "white",
    minWidth: "250px",
  },
  labelAuto: {
    color: "white",
  },
}));

function ProgressIndicator() {
  return useSelector(getIsLoading) ? <LinearProgress/> : <div style={{ height: 4 }}></div>
}

export default function Dashboard() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const classes = useStyles();

  const lang = useSelector(getLang);
  const oauthServer = useSelector(getOauthServer);
  const selectedDataSetId = useSelector(getSelectedDataSetId);
  const projectDatasets = useSelector(getProjectDatasets);
  const locationPath = useSelector(getLocationPath);
  const projects = useSelector(getProjects);
  const projectId = useSelector(getProjectId);
  const project = projects?.find(p => p._id == projectId);
  const tokenAfterLogon = useSelector(getToken);

  const isLoggedIn = tokenAfterLogon || Boolean(localStorage.getItem(SOME_SETTINGS.TOKEN));

  const [open, setOpen] = useState(isLoggedIn);
  const [showSettings, setShowSettings] = useState();

  const is_genv = isGAI(project);
  const is_asr = isASR(project);
  const locationSection = Location.getSection() || 'projects';
  const locationProjectId = Location.getProjectId();

  const [currentPathname, setCurrentPathname] = useState(locationSection);
  const isLocationPath = p => currentPathname == p;

  useEffect(() => {
    // console.log('USE EFFECT SET PATH: ', locationSection);
    setCurrentPathname(locationSection);

    const open = localStorage.getItem('menu_open');
    setOpen(isLoggedIn && (open == undefined || open == 'true'));
  }, [tokenAfterLogon, locationSection]);

  useEffect(() => {
    if (isLoggedIn)
      dispatch(initGlobals(projects, projectId, t));
  }, [tokenAfterLogon]);

  /*
  useEffect(() => {
    const handleLocationChange = () => {
      console.log('CHANGED', locationProjectId, parseLocation());
      if (projects)
        dispatch(changeProject(locationProjectId));
    };
    window.addEventListener('popstate', handleLocationChange);
    return () => {
      window.removeEventListener('popstate', handleLocationChange);
    }
  }, []);
   */

  const DataSetSelector = React.useCallback(() => {
    if (!projectDatasets?.length)
      return null;

    const onChange = (event, value) => {
      dispatch(changeDataset(value._id));
      Location.push(`/annotation/${projectId}/${value._id}`);
    };

    return (
      <Typography component="div" variant="subtitle1" color="inherit"
                  style={{ display: "inline-block", marginLeft: "20px" }}>
        {t("dashboard.data_set")}:
        <Autocomplete
          size="small"
          style={{ marginLeft: "5px" }}
          classes={{
            paper: classes.paper,
            option: classes.option,
            inputRoot: classes.inputAuto,
            root: classes.rootAuto,
            popupIndicator: classes.labelAuto,
            clearIndicator: classes.labelAuto,
          }}
          disableClearable
          options={projectDatasets}
          getOptionLabel={(option) => option.name}
          value={projectDatasets?.find(ds => ds._id == selectedDataSetId) || null}
          onChange={onChange}
          filterSelectedOptions
          renderInput={(params) => (
            <TextField style={{ marginTop: "1px" }}
                       {...params}
                       size="small"
            />
          )}
        />
      </Typography>
    );
  }, [selectedDataSetId, projectDatasets]);

  function ProjectAndDatasetSelector() {
    const showType = d => (d?.type || 'NLU').slice(0,3);
    const ProjectSelector = () => [
      t("common.project") +': ',
      isLocationPath("projects") ? project?.name : (
          <Autocomplete
            key="ProjectSelect"
            size="small"
            style={{ marginLeft: 5, marginRight: 40 /* placeholder for project type (see <span> after <TextField> below) */ }}
            classes={{
              paper: classes.paper,
              option: classes.option,
              inputRoot: classes.inputAuto,
              root: classes.rootAuto,
              popupIndicator: classes.labelAuto,
              clearIndicator: classes.labelAuto,
            }}
            disableClearable
            options={projects}
            getOptionLabel={option => option.name}
            renderOption={option => (
              <Tooltip title={option.name} enterDelay={1000}>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'stretch', width: '100%'}}>
                  <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                    <div style={{overflow: 'hidden'}}>{option.name}</div>
                    <div style={{display: 'flex', opacity: 0.5, paddingLeft: 10, fontSize: 12, alignItems: 'center'}}>{showType(option)}</div>
                  </div>
                </div>
              </Tooltip>
            )}
            value={project || null}
            onChange={(event, value) => dispatch(changeProject(value._id, projects))}
            filterSelectedOptions
            renderInput={params => (<>
              <TextField style={{ paddingTop: 1 }}
                {...params}
                size="small"
              />
              <span style={{opacity: 0.5, paddingLeft: 10, fontSize: 12}}>{showType(project)}</span>
            </>)}
          />
      )
    ];

    const show_projects = !isLocationPath("sign-in") && projects?.length || null;
    return (
      <Typography component="h3" variant="subtitle1" color="inherit" noWrap className={classes.title}>
        <Box>
          {show_projects && ProjectSelector()}
          {show_projects && isLocationPath("annotation") && DataSetSelector()}
        </Box>
      </Typography>
    );
  }

  const _setOpen = (v) => { localStorage.setItem('menu_open', v); setOpen(v); }

  const handleDrawerOpen =  () => { _setOpen(true) };
  const handleDrawerClose = () => { _setOpen(false) };

  const handleLogOut = () => {
    dispatch(setToken(null));
    localStorage.clear();
    localStorage.setItem('menu_open', open);
    Location.push("/sign-in");
  };

  const _IsAdmin = getRunConfigOption('enableRoleModel') && IsAdmin();

  const isInLeftMenu = is_menu => r => {
    const tests = [
      ['users', _IsAdmin && !oauthServer],
      ['user_groups', _IsAdmin],
      ['intents  slots  entities  filters  auto_annotation batches', !is_genv && !is_asr],
    ];
    const is_in = ([s_names, test]) => !s_names.split(/ +/).includes(r.section) || test;
    return !(is_menu && r.lmenu === false) && tests.every(is_in);
  };

  /** When fixScroll style is applied, on Annotation tab, when we change to AnnotationSimilarity,
   * <main> suddenly and for unknown reason scrolls down the size of <AppBar> and it top goes up
   * beyond visibility, so this fix is intended to undo this strange behavior.
   */
  const fixUncontrolledScroll = e => {
    const n = e.target;
    if (n == e.currentTarget && n.scrollTop) {
      console.log('fixUncontrolledScroll', n.scrollTop, n);
      n.scrollTo(0,0);
    }
  };

  const ref = useRef();

  // console.log('RENDER dashboard:', currentPathname);
  return (
    <div className={classes.root}>
      <SnackbarMessage/>
      {showSettings && <SettingsDialog onClose={() => setShowSettings(false)}/>}
      <Router history={Location.getHistoryObj()}>
        <CssBaseline/>
        <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
          <Toolbar className={classes.toolbar}>
            {isLoggedIn &&
              <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
              >
                <MenuIcon/>
              </IconButton>}
            {ProjectAndDatasetSelector()}
            {/* Breadcrumbs */}
            <div style={{ marginRight: 10 }}><LanguageSelect/></div>
            {isLoggedIn && <Box m={2}>{_IsAdmin ? t("common.admin") : t("common.user_name")}: {GetUserName()}</Box>}
            {isLoggedIn && (
              <IconButton onClick={() => setShowSettings(true)} title={t("common.settings")} color="inherit">
                <SettingsIcon color="inherit"/>
              </IconButton>
            )}
            <InfoPopover/>
            {isLoggedIn &&
              <IconButton onClick={handleLogOut} title={t("common.sign_out")} color="inherit">
                <ExitToAppIcon color="inherit"/>
              </IconButton>}
          </Toolbar>
          <ProgressIndicator/>
        </AppBar>
        <Drawer
          variant="permanent"
          classes={{
            paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
          }}
          open={open}
        >
          <div className={classes.toolbarIcon}>
            <IconButton aria-label={t("common.logout")} onClick={handleDrawerClose}>
              <ChevronLeftIcon/>
            </IconButton>
          </div>
          <Divider/>
          <List>
            {Location.Routes.filter(isInLeftMenu(true)).map((r, idx) => {
              const item = r.section;
                const _pr = projectId ? '/'+ projectId : '';
                const _ds = item == "annotation" ? `/${selectedDataSetId || ""}` : "";
                const no_datasets = ["annotation", "train", "tests", "auto_annotation", "batches"].includes(item)
                  && !projectDatasets?.length;

                return (
                  <ListItem
                    disabled={!(projectId || r.is_admin || item == 'projects') || no_datasets}
                    selected={isLocationPath(item)}
                    onClick={() => setCurrentPathname(item)}
                    button
                    component={Link}
                    to={`/${item}${_pr}${_ds}`}
                    key={"list-" + idx}
                  >
                    <ListItemText className={clsx(r.is_admin && classes.menuItemAdmin)}>
                      {t("menu." + item)}
                    </ListItemText>
                  </ListItem>
                );
              })
            }
          </List>
        </Drawer>
        <main className={classes.main} onScroll={fixUncontrolledScroll}>
          <div className={classes.appBarSpacer}/>
          <Container ref={ref} maxWidth={false} className={classes.container}>
            <Grid container spacing={3}>
              {/* main container with preview info*/}
              <Switch>
                <Route exact path="/">
                  <Redirect to="/projects"/>
                </Route>
                <Route exact path="/sign-in">
                  <SignIn {...{ oauthServer }}/>
                </Route>
                <Route exact path="/nlu_suite_callback">
                  <OAuthCallback/>
                </Route>
                {Location.Routes.filter(isInLeftMenu()).map(({ path, Component, exact, is_admin }) => (
                  (!is_admin || _IsAdmin) && (
                    <PrivateRoute key={String(path)} path={path} exact={exact}>
                      {projects && <Component containerRef={ref}/>}
                    </PrivateRoute>
                  )
                ))}
              </Switch>
            </Grid>
            <SimpleBackdrop/>
          </Container>
        </main>
      </Router>
    </div>
  );
}

function PrivateRoute({ children, ...rest }) {
  const token = GetToken();

  return (
    <Route
      {...rest}
      render={({ location }) =>
        token ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/sign-in",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}
